<template>
  <div>
    <movement-buttons
      :orientation="'horizontal'"
      :disabled="disabled"
      :glow="glow"
      @position="changeMovementX"
      @end-contact="endContact"
    />
  </div>
</template>

<script lang="ts">

import { MovementButtons } from '@powerplay/core-minigames-ui-ssm'
import {
  game,
  inputsManager,
  modes
} from '@powerplay/core-minigames'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { Sides } from '@/app/types'

export default defineComponent({
  components: {
    MovementButtons
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    glow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isHorizontal: 'MovementState/getIsHorizontal',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      forceJoystick: 'InputsState/getIsForceJoystick',
    })
  },
  methods: {
    endContact() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()
      this.$store.commit('MovementState/SET_POSITION_X', 0)
      this.$store.commit('MovementState/SET_POSITION_Y', 0)

    },
    changeMovementX(positionX: number) {

      if (this.disabled) return

      if (modes.isTutorial() && game.paused) {

        tutorialFlow.changePathInput(positionX > 0 ? Sides.RIGHT : Sides.LEFT)

      }
      this.$store.commit('MovementState/SET_POSITION_X', positionX)

    }

  }
})

</script>
