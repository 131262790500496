import type { Module } from 'vuex'
import type { RootState } from '../index'
import { ArrowTypes } from '@/app/types'


export interface ArrowDistancesState {
  leftType: string,
  leftValue: number,
  middleType: string,
  middleValue: number,
  rightType: string,
  rightValue: number,
  limitToShow: number,
  showArrows: boolean
}

const initialState = () => ({
  leftType: ArrowTypes.none,
  leftValue: 0,
  middleType: ArrowTypes.none,
  middleValue: 0,
  rightType: ArrowTypes.none,
  rightValue: 0,
  limitToShow: 0,
  showArrows: false
})

const arrowDistancesState: Module<ArrowDistancesState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getArrowDistances: (moduleState: ArrowDistancesState) => moduleState
  },

  mutations: {
    RESET: (moduleState: ArrowDistancesState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: ArrowDistancesState, newState: ArrowDistancesState) => {

      if (newState.leftType !== undefined) moduleState.leftType = newState.leftType
      if (newState.leftValue !== undefined) moduleState.leftValue = newState.leftValue
      if (newState.middleType !== undefined) moduleState.middleType = newState.middleType
      if (newState.middleValue !== undefined) moduleState.middleValue = newState.middleValue
      if (newState.rightType !== undefined) moduleState.rightType = newState.rightType
      if (newState.rightValue !== undefined) moduleState.rightValue = newState.rightValue
      if (newState.limitToShow !== undefined) moduleState.limitToShow = newState.limitToShow

    },
    SET_SHOW: (moduleState: ArrowDistancesState, newState: boolean) => {

      moduleState.showArrows = newState

    }
  }
}

export default arrowDistancesState
