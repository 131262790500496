/**
 * Mena sekcie
 */
export enum SectionNames {
  handCheck = 'handCheck',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  endSection = 'endSection'
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  awaitingEvent = 'awaitingEvent',
  start = 'start',
  slipStreamSuccess = 'slipStreamSuccess',
  tooFar10 = 'tooFar10',
  changePathTimeout = 'changePathTimeout',
  changePathSuccess = 'changePathSuccess',
  changePathDone = 'changePathDone',
  requiredSpeed = 'requiredSpeed',
  last200m = 'last200m',
  finish = 'finish',
}

export enum TutorialObjectiveIds {
  slipStream = 'tutorialTask-1',
  changePath = 'tutorialTask-2',
  requiredSpeed = 'tutorialTask-3',
  finish = 'tutorialTask-4'
}
