import { PlayerAnimationsNames } from '../types'

/**
 * Specialny konfig pre hracove animacie
 */
export const playerAnimationConfig = {

  /**
   * Zakladny cas pre crossfade
   */
  defaultCrossfadeTime: 0.02,

  /** max rychlost animacii */
  animationSpeedMax: 1.5,

  /** pocet framov na zmenu sprint/tempo */
  animationChangeFrames: 10,

  /** ako casto pocitat ci sa ma pozriet dozadu - min a max z koreho sa vygeneruje hodnota */
  lookBackFrames: {
    min: 60,
    max: 90
  },

  /** aka je pravdepodobnost, ze sa pozrie dozadu */
  lookBackPercent: 70,

  /** o kolko sa meni weight pri obzerani */
  lookBackSpeed: 0.1,

  /** kombinacie pre prepare animacie */
  prepareAnimationsChains: [
    [
      PlayerAnimationsNames.prepare1,
      PlayerAnimationsNames.prepare2,
      PlayerAnimationsNames.prepare3,
      PlayerAnimationsNames.prepare4,
      PlayerAnimationsNames.prepare5,
      PlayerAnimationsNames.prepare6
    ],
    [
      PlayerAnimationsNames.prepare2,
      PlayerAnimationsNames.prepare3,
      PlayerAnimationsNames.prepare4,
      PlayerAnimationsNames.prepare5,
      PlayerAnimationsNames.prepare6,
      PlayerAnimationsNames.prepare1
    ],
    [
      PlayerAnimationsNames.prepare3,
      PlayerAnimationsNames.prepare4,
      PlayerAnimationsNames.prepare5,
      PlayerAnimationsNames.prepare6,
      PlayerAnimationsNames.prepare1,
      PlayerAnimationsNames.prepare2
    ],
    [
      PlayerAnimationsNames.prepare4,
      PlayerAnimationsNames.prepare5,
      PlayerAnimationsNames.prepare6,
      PlayerAnimationsNames.prepare1,
      PlayerAnimationsNames.prepare2,
      PlayerAnimationsNames.prepare3
    ],
    [
      PlayerAnimationsNames.prepare5,
      PlayerAnimationsNames.prepare6,
      PlayerAnimationsNames.prepare1,
      PlayerAnimationsNames.prepare2,
      PlayerAnimationsNames.prepare3,
      PlayerAnimationsNames.prepare4
    ],
    [
      PlayerAnimationsNames.prepare6,
      PlayerAnimationsNames.prepare1,
      PlayerAnimationsNames.prepare2,
      PlayerAnimationsNames.prepare3,
      PlayerAnimationsNames.prepare4,
      PlayerAnimationsNames.prepare5
    ],
  ]

}
