/**
 * Konfig pre pomocnikov
 */
export const helperManConfig = {

  // ako rychlo sa hybe pomocnik, dava sa cez random
  animationStep: {
    min: 0.45,
    max: 0.65
  },

  // pocet frameov, kolko bude pomocnik cakat, kym pojde pohyb do opacnej strany
  freezeFrames: {
    min: 40,
    max: 80
  }

}