import {
  animationsConfigBicycle,
  gameConfig
} from '@/app/config'
import {
  AnimationsManager,
  THREE,
  fpsManager,
  game
} from '@powerplay/core-minigames'
import { ModelsNames } from '@/app/types'
import type { Athlete } from '../athlete'
import type { Opponent } from './opponent/Opponent'

/**
 * Trieda pre bicykel
 */
export class Bicycle {

  /** Rig pre bicykel, resp vsetky kosti a skinned meshe v jednom */
  public rig!: THREE.Object3D

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Vytvorenie luku
   */
  public create(): void {

    // musime dat rig bicykla prec z objektu hraca
    this.rig = this.athlete.athleteObject.getObjectByName('rig_bike') as THREE.Object3D
    console.log(this.athlete.athleteObject)

    // skinned meshu musime vypnut frustumCulled, lebo sa to sprava, akoby bol na povodnej pozicii
    const sufix = this.athlete.playable ? '' : `_opponent_${(this.athlete as Opponent).materialIndex}`
    const skinnedMesh = this.rig.getObjectByName(`bike_low${sufix}`) as THREE.Object3D
    if (skinnedMesh) {

      skinnedMesh.frustumCulled = false
      skinnedMesh.castShadow = true

    }

    // animacie
    this.animationsManager = new AnimationsManager(
      this.rig,
      animationsConfigBicycle,
      game.animations.get(ModelsNames.athlete),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

  }

  /**
   * Nastavenie viditelnosti
   * @param visibility - Viditelnost
   */
  public setVisibility(visibility: boolean): void {

    this.rig.visible = visibility

  }

  /**
   * Aktualizovanie luku
   * @param delta - Delta
   */
  public update(delta: number): void {

    // aktualizujeme animacie
    this.animationsManager.update(delta)

  }

  /**
   * reset luku
   */
  public reset(): void {

    this.animationsManager.resetAll()

  }

}

