/** Nazvy zvukov */
export enum AudioNames {

  spriteAudience = 'audienceSprite',
  spriteCommentators = 'commentators',

  audienceIntroduction = 'audience-hyped-overlay-5-introduction',
  audienceRace = '100m_hyped_overlay',
  audienceFinish = 'audience-hyped-overlay-5-finish',
  audienceIntro = 'audience-noise',
  heartbeat = 'BI_heartbeat',
  breathing = 'BI_heavy_breathing',
  lastRoundBell = 'last_round_bell',

  commentatorFinishRank13 = 'VO_cycling_finish_rank1_3_var01',
  commentatorFinishRank13v2 = 'VO_cycling_finish_rank1_3_var012',
  commentatorFinishRank1 = 'VO_cycling_finish_rank1_var01',
  commentatorFinishRank1v2 = 'VO_cycling_finish_rank1_var012',
  commentatorFinishRank23 = 'VO_cycling_finish_rank2_3',
  commentatorFinishRank4 = 'VO_cycling_finish_rank4',
  commentatorLap1Rank1 = 'VO_cycling_lap1_rank1',
  commentatorLap1Rank23 = 'VO_cycling_lap1_rank2_3',
  commentatorLap1Rank4 = 'VO_cycling_lap1_rank4',
  commentatorLap2Rank1 = 'VO_cycling_lap2_rank1',
  commentatorLap2Rank23 = 'VO_cycling_lap2_rank2_3',
  commentatorLap2Rank4 = 'VO_cycling_lap2_rank4',
  commentatorFinalResults = 'VO_cycling_final_result_var01',
  commentatorFinalResults2 = 'VO_cycling_final_result_var012',
  commentatorIntro = 'VO_cycling_intro_var01',
  commentatorIntro2 = 'VO_cycling_intro_var012',
  commentatorStart = 'VO_cycling_start_var01',
  commentatorStart2 = 'VO_cycling_start_var012'

}

/** Kategorie zvukov */
export enum AudioCategories {

  player = 'player',
  stadium = 'stadium',
  audience = 'audience',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators'

}
