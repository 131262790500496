import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface TextMessageState {
  showFinalLap: boolean,
  showCountdown: boolean,
  showType: number,
  showTogo: boolean
}

const initialState = () => ({
  showFinalLap: false,
  showCountdown: false,
  showType: 0,
  showTogo: false
})

const textMessageState: Module<TextMessageState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTextMessageState: (moduleState: TextMessageState) => moduleState,
  },

  mutations: {
    RESET: (moduleState: TextMessageState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TextMessageState, newState: TextMessageState) => {

      moduleState.showFinalLap = newState.showFinalLap
      moduleState.showCountdown = newState.showCountdown
      moduleState.showType = newState.showType

    },
    SET_SHOW_FINAL_LAP: (moduleState: TextMessageState, showFinalLap: false) => {

      moduleState.showFinalLap = showFinalLap

    },
    SET_SHOW_COUNTDOWN: (moduleState: TextMessageState, showCountdown: false) => {

      moduleState.showCountdown = showCountdown

    },
    SET_SHOW_TOGO: (moduleState: TextMessageState, showTogo: false) => {

      moduleState.showTogo = showTogo

    },
    SET_SHOW_TYPE: (moduleState: TextMessageState, showType: number) => {

      moduleState.showType = showType

    }
  }
}

export default textMessageState
