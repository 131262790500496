import {
  type MaterialDataObject,
  THREE
} from '@powerplay/core-minigames'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {

  [MaterialsNames.athlete]: {
    meshesArray: [
      'body_low', 'f_body_low'
    ],
    textureName: TexturesNames.athleteClothes,
    playerIndex: 0
  },
  [MaterialsNames.athleteOpponent1]: {
    meshesArray: [
      'body_low_opponent_1', 'f_body_low_opponent_1'
    ],
    textureName: TexturesNames.athleteClothesOpponent1,
    playerIndex: 1

  },
  [MaterialsNames.athleteOpponent2]: {
    meshesArray: [
      'body_low_opponent_2', 'f_body_low_opponent_2'
    ],
    textureName: TexturesNames.athleteClothesOpponent2,
    playerIndex: 2

  },
  [MaterialsNames.athleteOpponent3]: {
    meshesArray: [
      'body_low_opponent_3', 'f_body_low_opponent_3'
    ],
    textureName: TexturesNames.athleteClothesOpponent3,
    playerIndex: 3

  },
  [MaterialsNames.athleteOpponent4]: {
    meshesArray: [
      'body_low_opponent_4', 'f_body_low_opponent_4'
    ],
    textureName: TexturesNames.athleteClothesOpponent4,
    playerIndex: 4

  },
  [MaterialsNames.athleteOpponent5]: {
    meshesArray: [
      'body_low_opponent_5', 'f_body_low_opponent_5'
    ],
    textureName: TexturesNames.athleteClothesOpponent5,
    playerIndex: 5

  },
  [MaterialsNames.bicycle]: {
    meshesArray: ['bike_low'],
    textureName: TexturesNames.bicycle,
  },
  [MaterialsNames.bicycleOpponent1]: {
    meshesArray: ['bike_low_opponent_1'],
    textureName: TexturesNames.bicycleOpponent1,

  },
  [MaterialsNames.bicycleOpponent2]: {
    meshesArray: ['bike_low_opponent_2'],
    textureName: TexturesNames.bicycleOpponent2,

  },
  [MaterialsNames.bicycleOpponent3]: {
    meshesArray: ['bike_low_opponent_3'],
    textureName: TexturesNames.bicycleOpponent3,

  },
  [MaterialsNames.bicycleOpponent4]: {
    meshesArray: ['bike_low_opponent_4'],
    textureName: TexturesNames.bicycleOpponent4,

  },
  [MaterialsNames.bicycleOpponent5]: {
    meshesArray: ['bike_low_opponent_5'],
    textureName: TexturesNames.bicycleOpponent5,

  },
  [MaterialsNames.audience]: {
    textureName: TexturesNames.audience,
    meshesArray: ['TrackCycling_Mesh001_1'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.colorGradient]: {
    textureName: TexturesNames.colorGradient,
    meshesArray: ['TrackCycling_Mesh001'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.concrete]: {
    textureName: TexturesNames.concrete,
    meshesArray: ['TrackCycling_Mesh001_2'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.glassPanel]: {
    textureName: TexturesNames.glassPanel,
    meshesArray: ['TrackCycling_Mesh001_3'],
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.grass]: {
    textureName: TexturesNames.grassUncut,
    meshesArray: ['TrackCycling_Mesh001_4'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.panel]: {
    textureName: TexturesNames.panel,
    meshesArray: ['TrackCycling_Mesh001_6'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.seats]: {
    textureName: TexturesNames.seats,
    meshesArray: ['TrackCycling_Mesh001_9'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
    alpha: 0.5, // alphatest
  },
  [MaterialsNames.stairs]: {
    textureName: TexturesNames.stairs,
    meshesArray: ['TrackCycling_Mesh001_10'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.trees]: {
    textureName: TexturesNames.trees,
    meshesArray: ['TrackCycling_Mesh001_11'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    vertexColors: true,
  },
  [MaterialsNames.trapNet]: {
    textureName: TexturesNames.trapNet,
    meshesArray: ['TrackCycling_Mesh001_8'],
    lightmap: TexturesNames.envLightmap,
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.npc]: {
    textureName: TexturesNames.npc,
    meshesArray: ['TrackCycling_Mesh001_5'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.npcStart1]: {
    textureName: TexturesNames.npc1,
  },
  [MaterialsNames.npcStart2]: {
    textureName: TexturesNames.npc2,
  },
  [MaterialsNames.npcStart3]: {
    textureName: TexturesNames.npc3,
  },
  [MaterialsNames.plastic]: {
    textureName: TexturesNames.plastic,
    meshesArray: ['TrackCycling_Mesh001_7'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.woodFloor]: {
    textureName: TexturesNames.woodFloor,
    meshesArray: ['TrackCycling_Mesh001_12'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.finish]: {
    textureName: TexturesNames.gradient,
    meshesArray: ['finish_TrackCycling_Finish'],
    vertexColors: true,
    transparent: true,
  },
  [MaterialsNames.lightsGradient]: {
    textureName: TexturesNames.gradient,
    transparent: true,
    vertexColors: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
    depthTest: false,
    meshesArray: ['env_LightBeams'],
  },
  [MaterialsNames.skybox]: {
    textureName: TexturesNames.skybox,
    meshesArray: ['SkyboxDay_Skybox_BG']
  },
  [MaterialsNames.skyboxMontain]: {
    textureName: TexturesNames.skyboxMountain,
    alpha: 0.5,
    meshesArray: ['SkyboxDay_Skybox_FG']
  },
  [MaterialsNames.skyboxClouds]: {
    textureName: TexturesNames.skyboxClouds,
    meshesArray: ['SkyboxDay_Skybox_Clouds'],
    transparent: true
  },
  [MaterialsNames.skyboxBuilding1]: {
    textureName: TexturesNames.skyboxBuilding1,
    meshesArray: ['Buildings_Mesh_1'],
    alpha: 0.5
  },
  [MaterialsNames.skyboxBuilding2]: {
    textureName: TexturesNames.skyboxBuilding2,
    meshesArray: ['Buildings_Mesh'],
    alpha: 0.5
  },
  [MaterialsNames.onlyVertexColors]: {
    isDefault: true,
    meshesArray: [],
    vertexColors: true,
    // lightmap: TexturesNames.envLightmapDark,
  }

}
