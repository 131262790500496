import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'
import { audioSprites } from './spriteAudioConfig'
/**
 * Trieda konfigu pre zvuky
 */
export const audioConfig: AudioObject[] = [

  ...audioSprites,

  {
    files: [
      AudioNames.audienceIntroduction
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 2,
    category: AudioCategories.audience
  },

  {
    files: [
      AudioNames.heartbeat
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.player
  },

  {
    files: [
      AudioNames.breathing
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.player
  },

  {
    files: [
      AudioNames.lastRoundBell
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.stadium
  },

  {
    files: [
      AudioNames.commentatorIntro,
      AudioNames.commentatorIntro2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    ignoreModes: [3, 14, 9, 10],
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },
  {
    files: [
      AudioNames.commentatorStart,
      AudioNames.commentatorStart2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    ignoreModes: [3, 14, 9, 10],
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },
  {
    files: [
      AudioNames.commentatorFinalResults,
      AudioNames.commentatorFinalResults2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    ignoreModes: [3, 14, 9, 10],
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },

]
