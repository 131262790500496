<template>
  <div
    v-if="!showTable"
    class="text-message-component"
    :style="{transform: `scale(${scaleCoef})`}"
  >
    <text-message
      v-if="textMessageState.showFinalLap"
      style="position:absolute;left:50%; top: 2%"
      first-line-text="final-lap"
      show-first-line
      with-animation
      :show-type="3"
      :is-scaled="false"
    />
    <text-message
      v-if="textMessageState.showCountdown"
      :key="textMessageState.showType"
      style="position:absolute;left:50%; top: 2%"
      first-line-text="keirin-start-texts"
      :first-line-sprite-type="textMessageState.showType"
      show-first-line
      with-animation
      :show-type="3"
      :is-scaled="false"
    />
    <text-message
      v-if="textMessageState.showTogo"
      :key="textMessageState.showType"
      style="position:absolute;left:50%; top: 2%"
      first-line-text="keirin-togo-texts"
      :first-line-sprite-type="textMessageState.showType"
      show-first-line
      with-animation
      :show-type="3"
      :is-scaled="false"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import {
  TextMessage,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TextMessage
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      textMessageState: 'TextMessageState/getTextMessageState',
      showTable: 'TableState/getShowTable',
    })
  }
})

</script>

<style lang="less" scoped>
.text-message-component {
  position: absolute;
  top: 14%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  transform-origin: top;
}
</style>
