import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre training
 */
export const trainingConfig = {

  /** kvalita umiestnenia v cieli */
  finishPositionQuality: [
    1,
    0.90,
    0.75,
    0.50,
    0.25,
    0
  ],

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: undefined,
    [CameraStates.intro2]: undefined,
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(-17.553674697875977, 1.6311092376708984, 26.99440574645996),
      endPosition: new THREE.Vector3(-6.966292381286621, 1.8096580505371094, 26.94769859313965),
      startRotation: new THREE.Vector3(1.5709475733837353, 0.0060364227749000075, 1.5539439334268423),
      endRotation: new THREE.Vector3(1.5708151652485536, 0.021748187985792342, 1.5722704084072239),
      fov: 54.5,
      duration: 5
    },
    [CameraStates.disciplineIntro]: {
      startPosition: new THREE.Vector3(-8.20163428, 1.3392995581, 0.37076743),
      endPosition: new THREE.Vector3(-5.370808, 1.27990091, 0.223722456),
      startRotation: new THREE.Vector3(1.5700448403224243, -0.016752746437589318, 1.5223709134100865),
      endRotation: new THREE.Vector3(1.5703778525076457, -0.008899140301990828, 1.5171237648503846),
      duration: 3,
      fov: 34.2,
      fovEnd: 45.4,
      offsetCamera: true
    },
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(6, 2, 0),
      endPosition: new THREE.Vector3(6, 2, 0),
      duration: 10,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5,

  /** kolko dostane % za predbehnute */
  overtakingPercent: 0.2,
  /** kolko dostane % za slipstream */
  slipStreamPercent: 0.005

}
