import {
  audioManager,
  modes,
  playersManager
} from '@powerplay/core-minigames'
import {
  AudioGroups,
  AudioNames
} from '../types'
import { player } from '../entities/athlete/player'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'

/**
 * metody ktore pomahaju pri spustani zvukov
 */
export class AudioHelper {

  /** ci sa ma cakat na spustenie zvukov divakov v cieli */
  private waitForFinishAudience = false

  /** Frame coutner pre audience v cieli */
  private framesCounterFinishAudience = 0

  /** Frame coutner pre komentatora v cieli */
  private framesCounterFinishCommentator = 0

  /** Pocet hracov co presli zaciatkom predposledneho kola pred hracom co hra */
  private playersCountPreFinalLap = 0

  /** Pocet hracov co presli zaciatkom posledneho kola pred hracom co hra */
  private playersCountFinalLap = 0

  /** Ci uz zacal zvuk tlkotu srdca */
  private audioStartedHeartbeat = false

  /** Ci uz zacal zvuk dychania */
  private audioStartedBreathing = false

  /**
   * Prehratie zvuku predposledneho kola
   * @param playable - Ci je to hrac co hra
   */
  public playAudioPreFinalLap(playable: boolean): void {

    if (!playable) {

      this.playersCountPreFinalLap += 1
      return

    }

    const position = this.playersCountPreFinalLap + 1
    let audioName = AudioNames.commentatorLap1Rank4
    if (position === 1) {

      audioName = AudioNames.commentatorLap1Rank1

    } else if (position <= 3) {

      audioName = AudioNames.commentatorLap1Rank23

    }
    audioManager.play(audioName)

  }

  /**
   * Prehratie zvuku posledneho kola
   * @param playable - Ci je to hrac co hra
   */
  public playAudioFinalLap(playable: boolean): void {

    if (!playable) {

      this.playersCountFinalLap += 1
      return

    }

    audioManager.play(AudioNames.lastRoundBell)

    const position = this.playersCountFinalLap + 1
    let audioName = AudioNames.commentatorLap2Rank4
    if (position === 1) {

      audioName = AudioNames.commentatorLap2Rank1

    } else if (position <= 3) {

      audioName = AudioNames.commentatorLap2Rank23

    }
    audioManager.play(audioName)

  }

  /**
   * Sprava zvuku dychania pri sprinte
   * @param sprint - ci sprintuje akurat
   */
  public manageBreathing(sprint: boolean): void {

    if (player.isEnd) return

    if (!this.audioStartedBreathing && sprint) audioManager.play(AudioNames.breathing)
    audioManager.changeAudioVolume(AudioNames.breathing, sprint ? 1 : 0)

  }

  /**
   * Sprava zvuku tlkotu srdca pri prazdnom sprint bare
   * @param sprint - ci je prazdny sprint bar
   */
  public manageHeartbeat(sprintBarEmpty: boolean): void {

    if (player.isEnd) return

    if (!this.audioStartedHeartbeat && sprintBarEmpty) audioManager.play(AudioNames.heartbeat)
    audioManager.changeAudioVolume(AudioNames.heartbeat, sprintBarEmpty ? 1 : 0)

  }

  /**
   * Zastavenie zvukov dychania
   */
  public stopBreathing(): void {

    audioManager.stopAudioByName(AudioNames.breathing)
    this.audioStartedBreathing = false

  }

  /**
   * Zastavenie zvukov tlkotu srdca
   */
  public stopHeartbeat(): void {

    audioManager.stopAudioByName(AudioNames.heartbeat)
    this.audioStartedHeartbeat = false

  }

  /**
   * Veci po prejdeni atleta cielom
   * @param playable - Ci je to hrac co hra
   */
  public afterAthletePassedFinish(playable: boolean): void {

    if (!this.waitForFinishAudience) this.framesCounterFinishAudience = 10
    if (playable) this.framesCounterFinishCommentator = 10

    this.waitForFinishAudience = true

  }

  /**
   * pustime komentatora
   */
  private playCommentatorAudio(): void {

    playersManager.setStandings(3)
    const pos = playersManager.getPlayerActualPosition(player.uuid)
    const standings = playersManager.getStandings()
    const photoFinishTimeLimit = 0.03
    let audio = AudioNames.commentatorFinishRank4

    if (pos <= 3 && (!modes.isEventBossFight() || pos === 1)) {

      const firstTime = disciplinePhasesManager.phaseFinish.getAthleteFinalTimeByUUID(standings[0].uuid)
      const secondTime = disciplinePhasesManager.phaseFinish.getAthleteFinalTimeByUUID(standings[1].uuid)
      let photoFinish = Math.abs(secondTime - firstTime) <= photoFinishTimeLimit

      // iba ked mame zaznam o tretom superovi, mozeme ho kontrolovat
      if (standings[2]) {

        const thirdTime = disciplinePhasesManager.phaseFinish.getAthleteFinalTimeByUUID(standings[2].uuid)
        photoFinish = photoFinish || Math.abs(thirdTime - firstTime) <= photoFinishTimeLimit

      }

      // ak bol fotofinish, tak specialne
      if (photoFinish) {

        audio = AudioNames.commentatorFinishRank13

      } else {

        audio = AudioNames.commentatorFinishRank23
        if (pos === 1) audio = AudioNames.commentatorFinishRank1

      }

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * updatujeme kazdy frame pri niektorych disciplinach
   * kde potrebujeme ratat framy pre spustanie zvukov
   */
  public update(): void {

    // cakanie na zvuky divakov po prejdeni prveho cyklistu
    if (this.framesCounterFinishAudience > 0) {

      this.framesCounterFinishAudience -= 1

      if (this.framesCounterFinishAudience <= 0) {

        audioManager.play(AudioNames.audienceFinish)
        audioManager.stopAudioByName(AudioNames.audienceRace)

      }

    }

    // cakanie na zvuky komentatora po prejdeni cyklistu hraca
    if (this.framesCounterFinishCommentator > 0) {

      this.framesCounterFinishCommentator -= 1

      if (this.framesCounterFinishCommentator <= 0) {

        this.playCommentatorAudio()

      }

    }

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.waitForFinishAudience = false
    this.framesCounterFinishAudience = 0
    this.framesCounterFinishCommentator = 0
    this.playersCountPreFinalLap = 0
    this.playersCountFinalLap = 0
    this.audioStartedHeartbeat = false
    this.audioStartedBreathing = false

  }

}

export const audioHelper = new AudioHelper()
