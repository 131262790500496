import type {
  PlayerStates,
  StartPositionsDataObject
} from '@/app/types'
import {
  minigameConfig,
  modes,
  playersManager,
  type PlayerInfo
} from '@powerplay/core-minigames'
import { player } from '../player'
import { Opponent } from './Opponent'
import { fakeOpponents } from './FakeOpponents'
import { gameConfig } from '@/app/config'

/** Trieda pre spravu protihracov */
export class OpponentsManager {

  /** Pole superov */
  private opponents: Opponent[] = []

  /** pocitadlo framov */
  private frameCounter = 0

  /** true ak v danom mode su opponenti */
  private isActive = true

  /** aktualna top3 hracov */
  private top3Players: string[] = []

  /** data z initu pri dennej lige */
  private dailyLeagueFinishData: PlayerInfo[] = []

  /**
   * Vrati pocet superov
   * @returns Pocet superov
   */
  public getOpponentsCount(): number {

    return this.opponents.length

  }

  /**
   * Vytvorenie superov
   * @param startData - Data
   * @param startingOrder - Poradie na starte
   * @param orderAnimationsPrepare - pole poradi pre prepare animacie
   */
  public create(
    startData: StartPositionsDataObject,
    startingOrder: StartPositionsDataObject,
    orderAnimationsPrepare: number[]
  ): void {

    if (!this.isActive) return

    playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= gameConfig.numberOfOpponents

    }).forEach((playerInfo: PlayerInfo, index: number) => {

      this.opponents[index] = new Opponent(playerInfo.uuid, index + 1)
      this.opponents[index].create(
        startData[playerInfo.uuid],
        startingOrder[playerInfo.uuid] || 0,
        orderAnimationsPrepare[index + 1]
      )

    })

  }

  /**
   * aktualizovanie superov pred fyzikou
   */
  public updateBeforePhysics(): void {

    if (!this.isActive) return

    this.frameCounter += 1

    this.opponents.forEach((opponent) => {

      opponent.updateBeforePhysics(this.frameCounter)

    })

  }

  /**
   * aktualizovanie superov po fyzikou
   */
  public updateAfterPhysics(): void {

    if (!this.isActive) return

    const playersPositions = [{ uuid: player.uuid,
      percent: player.getActualPercentOnPath() }]

    this.opponents.forEach((opponent) => {

      opponent.updateAfterPhysics()
      playersPositions.push({ uuid: opponent.uuid,
        percent: opponent.getActualPercentOnPath() })

    })

    playersPositions.sort((a, b) => {

      if (a.percent < b.percent) return 1
      return -1

    })

    for (let i = 0; i < 3; i++) {

      if (playersPositions[i]) {

        this.top3Players.push(playersPositions[i].uuid)

      }

    }
    // this.top3Players = [playersPositions[0].uuid, playersPositions[1].uuid, playersPositions[2].uuid]

  }

  /**
   * Nastavenie stavu vsetkym superom
   * @param state - stav
   * @param maxRandomDelay - maximalna hodnota randomu delayu od 0
   */
  public setStateToAll(state: PlayerStates, maxRandomDelay = 0): void {

    this.opponents.forEach((opponent) => {

      opponent.setState(state, maxRandomDelay)

    })

  }

  /**
   * Nastavenie pozicie supera na starte
   */
  public setActualPercentOnStart(): void {

    this.opponents.forEach((opponent) => {

      opponent.worldEnvLinesManager.setActualPercentOnStart()

    })

  }

  /**
   * Odstartovanie vsetkych superov
   */
  public start(): void {

    if (!this.isActive) return

    this.frameCounter = 0
    this.opponents.forEach((opponent) => {

      opponent.start()

    })

  }

  /**
   * Aktualizovanie animacii superov
   * @param delta - delta pre animacie
   */
  public updateAnimations(delta: number): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.updateAnimations(delta)

    })

  }

  /**
   * Getter
   * @returns oppontnes
   */
  public getOpponents(): Opponent[] {

    return this.isActive ? this.opponents : []

  }

  /**
   * Getter
   * @returns uuids
   */
  public getOpponentsIds(): string[] {

    const uuids = [] as string[]
    playersManager.players.filter((playerInfo: PlayerInfo, index: number) => {

      return !playerInfo.playable && index <= gameConfig.numberOfOpponents

    }).forEach((playerInfo: PlayerInfo) => {

      uuids.push(playerInfo.uuid)

    })
    return uuids

  }

  /**
   * Nastavenie DNF pre vsetkych superov co nepresli cielom
   * @returns
   */
  public setDnfIfNotReachedFinish(): void {

    if (!this.isActive) return
    this.opponents.forEach((opponent) => {

      if (!opponent.isEnd) playersManager.setPlayerResultsById(opponent.uuid, minigameConfig.dnfValue)

    })

  }

  /**
   * Vratenie originalnych dat realnych hracov
   */
  public setOriginalData(): void {

    for (let i = 1; i <= gameConfig.numberOfOpponents; i += 1) {

      playersManager.players[i] = this.dailyLeagueFinishData[i]

    }

  }

  /**
   * Prepisanie dat superov
   */
  public rewriteOpponentsData(): void {

    if (!modes.isTutorial() && !modes.isTrainingMode() && !modes.isDailyLeague()) return

    this.setFakeOpponentsData()

  }

  /**
   * Nastavenie fake dat pre tutorial a trening pre superov
   */
  private setFakeOpponentsData(): void {

    if (modes.isDailyLeague()) {

      for (let i = 1; i <= gameConfig.numberOfOpponents; i += 1) {

        this.dailyLeagueFinishData[i] = playersManager.players[i]
        fakeOpponents[i - 1].uuid = playersManager.players[i].uuid

      }

    }

    this.setFakeData()

  }

  /**
   * Vratenie fake dat hracov
   */
  public setFakeData(): void {

    for (let i = 1; i <= gameConfig.numberOfOpponents; i += 1) {

      playersManager.players[i] = fakeOpponents[i - 1]
      if (modes.isDailyLeague()) {

        playersManager.players[i].attribute.total = playersManager.getPlayer().attribute.total + 30

      } else {

        playersManager.players[i].attribute.total = playersManager.getPlayer().attribute.total

      }

    }

  }

  /**
   * Aktualizovanie animacii superov
   */
  public reset(): void {

    if (!this.isActive) return

    this.opponents.forEach((opponent) => {

      opponent.reset()

    })

  }

}

export const opponentsManager = new OpponentsManager()
