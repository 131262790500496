import {
  opponentConfig,
  pathsConfig
} from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  MaterialsNames,
  ModelsNames,
  Sides
} from '@/app/types'
import {
  game,
  gsap,
  THREE,
  playersManager,
  type PlayerInfo,
  modes,
  PlayerSex,
  ModeTypes
} from '@powerplay/core-minigames'
import { player } from '../player'
import { materialsConfig } from '@/app/config/materialsConfig'
import { Athlete } from '..'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

/** Trieda pre spravu protihraca */
export class Opponent extends Athlete {

  /** frames counter na rozhodovanie ai */
  private aiDecisionsFramesCounter = 0

  /** ci ma sprint rush - zapne sa pri sprint nad 95 */
  private isSprintRush = false

  /** specialna hodnota pre opponentov */
  public last200mSpecial = 200

  /** specialna hodnota pre cielovu rovinku */
  public finalSprintSpeedbar = 85

  /** pocet trati povolenych pre supera */
  private maxTracksAvailable = pathsConfig.tracks - 1

  /** max reaction distance */
  private maxReactionDistance = opponentConfig.ai.maxReactionDistance

  /** material hraca */
  private opponentMaterial!: THREE.ShaderMaterial

  /** material bicykla */
  private bicycleMaterial!: THREE.MeshBasicMaterial

  /**
   * Konstruktor
   * @param uuid - UUID spera
   * @param materialIndex - index materialu
   */
  public constructor(public uuid: string, public materialIndex: number) {

    super(uuid)

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const sex = playersManager.getPlayerById(this.uuid)?.sex
    let objectForClone
    if (playersManager.getPlayer().sex !== sex) {

      objectForClone = game.getObject3D('athlete_opponent')

    }
    console.log('HGE', objectForClone, this.uuid, playersManager.getPlayer().sex, sex)
    const athleteObject = objectForClone ?
      game.cloneSkeleton(objectForClone) :
      game.cloneSkeleton(player.athleteObject)

    materialsConfig[ModelsNames.athlete]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(MaterialsNames.athleteOpponentPrefix + this.materialIndex) as THREE.MeshBasicMaterial

    })
    materialsConfig[MaterialsNames.bicycle]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(`${MaterialsNames.bicycleOpponentPrefix + this.materialIndex }`) as THREE.MeshBasicMaterial

    })

    return athleteObject

  }

  /**
   * Vytvorenie protihraca
   * @param trackIndex - Index drahy
   * @param startingOrder - pozicia pri starte
   * @param animationPrepareChainIndex - Index pre retaz kombinacie prepare animacii
   */
  public create(trackIndex: number, startingOrder: number, animationPrepareChainIndex: number): void {

    console.log('vytvaram protihraca...', this.uuid)

    const info = playersManager.getPlayerById(this.uuid) as PlayerInfo
    const sufix = playersManager.getPlayer().sex !== info.sex ?
      '_opponent' :
      ''

    super.create(
      trackIndex,
      startingOrder,
      animationPrepareChainIndex,
      `Opponent${ this.uuid}`,
      ModelsNames.athlete + sufix
    )

    this.animationsManager.pauseAll()
    this.bicycle.animationsManager.pauseAll()
    gsap.to({}, {
      onComplete: () => {

        this.animationsManager.unpauseAll()
        this.bicycle.animationsManager.unpauseAll()

      },
      duration: 1
    })
    this.setFinalSprintSpeedbar()
    this.last200mSpecial = THREE.MathUtils.randFloat(200, 250)

    const prefixSex = (playersManager.getPlayerById(this.uuid)?.sex ?? PlayerSex.male) === PlayerSex.male ? '' : 'f_'
    const name = `${prefixSex}body_low_opponent_${this.materialIndex}`

    const opponentMesh = this.athleteObject.getObjectByName(name) as THREE.Mesh
    this.opponentMaterial = opponentMesh.material as THREE.ShaderMaterial
    this.opponentMaterial.side = THREE.FrontSide
    this.opponentMaterial.needsUpdate = true

    const bicycleMesh = this.athleteObject.getObjectByName(`bike_low_opponent_${this.materialIndex}`) as THREE.Mesh
    this.bicycleMaterial = bicycleMesh.material as THREE.MeshBasicMaterial
    this.bicycleMaterial.side = THREE.FrontSide
    this.bicycleMaterial.needsUpdate = true

    if (modes.isModeByParam(ModeTypes.eventBossFight)) {

      this.maxReactionDistance = opponentConfig.ai.maxReactionDistanceBossFight
      this.maxTracksAvailable = pathsConfig.tracks

    }

  }

  /**
   * Aktualizovanie hraca po vykoani fyziky
   */
  public updateAfterPhysics(): void {

    this.aiDecisions()
    super.updateAfterPhysics()
    this.setOpacityBehindPlayer()

  }

  /**
   * Rozhodovanie opponentov pocas preteku
   */
  private aiDecisions(): void {

    if (this.speedBarManager.inputsBlocked || this.isEnd) return
    this.aiDecisionsFramesCounter += 1
    const { speedBarConfig, sprintRush, turnLeftFrequency } = opponentConfig.ai


    // ak nie je uplne vlavo, skusime ist vlavo kazdych y framov, v boss fighte je to trochu inak
    if (
      this.worldEnvLinesManager.pathIndex > 0 &&
      this.aiDecisionsFramesCounter % turnLeftFrequency === 0 &&
      (!modes.isModeByParam(ModeTypes.eventBossFight) || !this.isInSlipStream || this.last200m)
    ) {

      if (this.changePath(Sides.LEFT)) this.aiDecisionsFramesCounter = 0

    }

    if (this.last200m) {

      this.decideLast200m()
      return

    }

    if (this.sprintBarManager.sprintBarValue > sprintRush.on) {

      this.isSprintRush = true

    }
    if (this.sprintBarManager.sprintBarValue <= sprintRush.off) {

      this.isSprintRush = false

    }

    let speedPower = 0
    // ak je pred cyklistom iny cyklista
    if (this.isInSlipStream) {

      speedPower = speedBarConfig.slipstream

      // nizky tempMax
      if (
        !modes.isModeByParam(ModeTypes.eventBossFight) &&
        this.speedBarManager.speedbarTempMax < speedBarConfig.splipstreamValueToChange
      ) {

        this.tryToGetAhead()

      }

    } else {

      // ak nie je blokovany, tak nastavime podla aktualneho kola
      speedPower = THREE.MathUtils.randFloat(speedBarConfig.noSlipstream.min, speedBarConfig.noSlipstream.max)

    }

    if (modes.isModeByParam(ModeTypes.eventBossFight)) {

      if (this.reactionDistance > 0 && this.reactionDistance < 10) this.tryToGetToPlayersLane()

    } else if (this.isSprintRush) {

      if (
        this.speedBarManager.speedbarTempMax > 0 &&
        this.speedBarManager.speedbarTempMax < this.speedBarManager.speedBarMax
      ) {

        this.tryToGetAhead()

      }

      speedPower = speedBarConfig.sprintRush

    }

    if (this.reactionDistance > (this.maxReactionDistance[this.worldEnvLinesManager.lap - 1] || 20)) {

      speedPower = THREE.MathUtils.randFloat(
        speedBarConfig.maxReactionDistance.min,
        speedBarConfig.maxReactionDistance.max
      )

    }

    if (this.aiDecisionsFramesCounter % this.getOpponentSpeedBarUpdateFrames()[0] !== 0) return
    if (modes.isTutorial() && !tutorialFlow.slipStreamSuccess) speedPower = 75
    this.speedBarManager.setSpeedPower(speedPower)

  }

  /**
   * Rozhodovanie v poslednych 200m
   */
  private decideLast200m(): void {

    if (this.inSafeZoneOfAthleteLastLap !== undefined) {

      this.tryToGetAhead()

    } else if (
      modes.isModeByParam(ModeTypes.eventBossFight) &&
      this.reactionDistance > 0 &&
      this.reactionDistance < 10
    ) {

      this.tryToGetToPlayersLane()

    }

    this.speedBarManager.setSpeedPower(this.finalSprintSpeedbar)

  }

  /**
   * Nastavenie finalSprintSpeedBar
   */
  private setFinalSprintSpeedbar(): void {

    const { finalSprintSpeedbar } = opponentConfig.ai.speedBarConfig
    this.finalSprintSpeedbar = THREE.MathUtils.randFloat(
      finalSprintSpeedbar[0].min,
      finalSprintSpeedbar[0].max
    )
    const strengthOpp = playersManager.getPlayerById(this.uuid)?.attribute.total || 0
    const strengthPlayer = playersManager.getPlayer()?.attribute.total || 0

    if (strengthPlayer > 100) {

      if (strengthOpp - strengthPlayer <= 0) {

        this.finalSprintSpeedbar = THREE.MathUtils.randFloat(
          finalSprintSpeedbar[1].min,
          finalSprintSpeedbar[1].max
        )

      } else if (strengthOpp - strengthPlayer <= 60) {

        this.finalSprintSpeedbar = THREE.MathUtils.randFloat(
          finalSprintSpeedbar[2].min,
          finalSprintSpeedbar[2].max
        )

      } else {

        this.finalSprintSpeedbar = THREE.MathUtils.randFloat(
          finalSprintSpeedbar[3].min,
          finalSprintSpeedbar[3].max
        )

      }

    }

  }

  /**
   * Pokusi sa predbehnut oponenta
   */
  private tryToGetAhead(): void {

    if (this.aiDecisionsFramesCounter % this.getOpponentSpeedBarUpdateFrames()[1] !== 0) return

    // skusime zmenit drahu
    if (!this.changePath(Sides.LEFT)) {

      if (this.worldEnvLinesManager.pathIndex >= this.maxTracksAvailable) return
      if (this.changePath(Sides.RIGHT)) this.aiDecisionsFramesCounter = 0

    } else {

      this.aiDecisionsFramesCounter = 0

    }

  }

  /**
   * Pokusi sa ist do drahy hraca
   */
  private tryToGetToPlayersLane(): void {

    if (this.aiDecisionsFramesCounter % this.getOpponentSpeedBarUpdateFrames()[1] !== 0) return

    const opponentLane = this.worldEnvLinesManager.pathIndex
    const playerLane = player.worldEnvLinesManager.pathIndex

    if (opponentLane < playerLane) {

      // hrac ja vo vonkajsej drahe, tak skusi ist super doprava
      if (this.changePath(Sides.RIGHT)) this.aiDecisionsFramesCounter = 0

    } else if (opponentLane > playerLane) {

      // hrac ja vo vnutornej drahe, tak skusi ist super dolava
      if (this.changePath(Sides.LEFT)) this.aiDecisionsFramesCounter = 0

    }

  }

  /**
   * Vrati spravne config values podla aktualneho kola
   */
  private getOpponentSpeedBarUpdateFrames(): number[] {

    return this.worldEnvLinesManager.lap >= 2 ?
      opponentConfig.ai.opponentSpeedBarUpdateFramesLastLap :
      opponentConfig.ai.opponentSpeedBarUpdateFrames

  }

  /**
   * Vymazanie vsetkych callbackov z animations managera
   */
  public removeCallbacksFromAllAnimations(): void {

    this.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    super.finishReached(actualPosition, lastPosition, finishPosition)
    if (!disciplinePhasesManager.phaseFinish.dailyLeagueSetResultsOpponentsFreeze) {

      playersManager.setPlayerResultsById(this.uuid, this.finalTime)

    }

    disciplinePhasesManager.phaseFinish.finishedOpponents += 1

  }

  /**
   * Nastavenie opacity supera a bicykla podla toho ci je za hracom alebo nie
   */
  private setOpacityBehindPlayer(): void {

    if (!disciplinePhasesManager.phaseRunning.runStarted) return

    const metersBehind = this.getMetersBehindPlayer()
    const samePath = this.worldEnvLinesManager.pathIndex === player.worldEnvLinesManager.pathIndex
    const correctDistance = metersBehind > 0 && metersBehind < 5
    const opacity = correctDistance && samePath && disciplinePhasesManager.phaseRunning.isActive ? 0.4 : 1

    this.opponentMaterial.uniforms.alpha.value = opacity
    this.opponentMaterial.transparent = opacity < 1

    this.bicycleMaterial.opacity = opacity
    this.bicycleMaterial.transparent = opacity < 1
    this.bicycleMaterial.needsUpdate = true

  }

  /**
   * Kontrolovanie ako daleko je hrac za inym cyklistom
   */
  private getMetersBehindPlayer(): number {

    return this.reactionDistance - player.reactionDistance

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    super.reset()

    this.aiDecisionsFramesCounter = 0
    this.isSprintRush = false

  }

}
