import store from '@/store'
// import { LoadingState } from '@powerplay/core-minigames-ui-ssm'
import {
  playersManager,
  settings,
  SettingsTypes,
  TimeManager,
  timeManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import { DisciplinePhases } from '../types'

export interface FpsDataVuex {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  private store = store

  public getFpsStarted(): boolean {

    return this.store.getters['FpsLookerState/getFpsStarted']

  }

  public setFpsData(fpsData: FpsDataVuex): void {

    this.store.commit('FpsLookerState/SET_FPS_STATE', fpsData)

  }

  public allowDirectionState(): void {

    this.store.commit('DirectionsState/SET_STATE', {
      show: true
    })

  }

  /*
   * public getLoadingState (): LoadingState {
   *
   *  return this.store.getters['LoadingState/getLoadingState']
   *
   * }
   */

  public setLoadingState(loadingState: unknown): void {

    this.store.commit('LoadingState/SET_STATE', loadingState)

  }

  public setBeforeGameStartPhase(): void {

    this.store.commit('GameSettingsState/SET_STATE', {
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType)
    })

  }

  public setUpdateTimeState(): void {

    let time = '0.0'
    if (disciplinePhasesManager.actualPhase >= DisciplinePhases.finish) {

      time = TimeManager.getTimeInSecondsLikeFormatted(playersManager.getPlayer().resultsArr?.[0].main || 0, 3)

    } else {

      time = timeManager.getGameTimeWithPenaltyInFormat(1)

    }
    store.commit('TimeState/SET_STATE', {
      time
    })

  }

  public hideSpeedVisibility(): void {

    this.store.commit('SpeedmeterState/SET_VISIBILITY', false)

  }

  public updateSpeedData(speedData: SpeedData): void {

    this.store.commit('SpeedmeterState/SET_STATE', speedData)

  }

}

export const stateManager = new StateManager()
