import type { THREE } from '@powerplay/core-minigames'

/** Strany */
export enum Sides {
  LEFT = 'Left',
  RIGHT = 'Right'
}

/** Specialny vektor na osiach X a Z */
export interface VectorXZ {
  x: number,
  z: number
}

/*
 * export type PositionTupleValue = 1 | -1
 * export type PosX = PositionTupleValue
 * export type PosY = PositionTupleValue
 * export type PositionTuple = [PosX, PosY]
 */
export type PositionTuple = [number, number]

/** Info ohladom priesecnika hraca a objektu */
export interface IntersectionInfo {

  normal: THREE.Vector3,
  distance: number,
  point: THREE.Vector3

}

/** Stavy hraca, hlavne pre animacie */
export enum PlayerStates {

  prepare = 'prepare',
  prepare2 = 'prepare2',
  starting = 'starting',
  tempo = 'tempo',
  sprint = 'sprint',
  lookBack = 'lookBack',
  end = 'end',
  endPath = 'endPath'

}

/** Typy emocii v cieli */
export enum EmotionTypesFinish {

  winner = 'winner',
  looser = 'looser',

}

/** Objekt vsetkych dat pre startovacie pozicie */
export type StartPositionsDataObject = { [key: string]: number }

