<template>
  <div
    class="blocking-effects-container"
    :style="rotationStyle"
  >
    <blocking-effect
      v-show="blockingState.side !== undefined"
      :is-visible="blockingState.isVisible && (blockingState.side !== undefined)"
      :side-x="sideX"
      side-y="top"
      :effect-type="effectType"
      :horizontal-position-offset="350"
      :vertical-position-offset="235"
      style="scale: 0.9"
    />
    <blocking-effect-center
      :is-visible="blockingState.isVisible && (blockingState.side === undefined)"
      side-x="center"
      side-y="top"
      rotate="90deg"
      :vertical-position-offset="50"
      :effect-type="effectType"
      :style="{scale:'0.35', transformOrigin: 'top'}"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import {
  BlockingEffect,
  BlockingEffectCenter
} from '@powerplay/core-minigames-ui-ssm'
import { WindowAspect } from '@powerplay/core-minigames-ui-ssm'
import { pathsConfig } from '@/app/config/pathsConfig'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlackOverlay',
  components: {
    BlockingEffect,
    BlockingEffectCenter
  },
  mixins: [WindowAspect],
  data() {

    return {
      effectType: ''
    }

  },
  computed: {
    ...mapGetters({
      blockingState: 'BlockingState/getBlockingState',
      leanOn: 'BlockingState/getBlockingLeanOn',
    }),
    sideX(): string {

      if (this.blockingState.side) {

        return this.blockingState.side.toLowerCase()

      }
      return ''

    },
    sideY(): string {

      if (this.blockingState.side) {

        return 'top'

      }
      return 'top-centered'

    },
    rotationStyle() {

      return {
        rotate: `${this.leanOn * -20}deg`,
        transformOrigin: 'bottom',
        position: this.isMobile() ? 'fixed' : 'absolute'
      }

    }
  },
  created() {

    this.effectType = pathsConfig.blockingEffectType

  }
})
</script>

<style lang="less" scoped>
.blocking-effects-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
