<template>
  <div
    v-show="showLaps"
    class="lap-container"
    :style="{transform: transformCoef}"
  >
    <record-box
      blue-text="LAP"
      :is-scaled="false"
    >
      <div style="color:#feb942">
        {{ lapPositionState.lap }}
      </div>
      <div>/3</div>
    </record-box>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import {
  RecordBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'
import { gameConfig } from '@/app/config/gameConfig'

export default defineComponent({
  components: {
    RecordBox
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      lapPositionState: 'LapPositionState/getLapPositionState'
    }),
    transformCoef() {

      return `scale(${this.scaleCoef})`

    },
    showLaps() {

      return this.lapPositionState.showLap &&
        this.lapPositionState.lap > 0 &&
        this.lapPositionState.lap <= gameConfig.numberOfLaps

    }
  }
})

/* eslint-disable */
</script>

<style scoped lang="less">

.lap-container {
  position: absolute;
  top: 1%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  
  transform-origin: center top;
}
</style>
