/**
 * Konfig pre opponenta/ov
 */
export const opponentConfig = {

  /** defaultMaxSpeedBar */
  defaultMaxSpeedBarValue: 100,

  /** pocty framov medzi ktorymi je random delay startu opponenta */
  startFrames: {

    from: 4,
    to: 7

  },

  /** hodnoty pre speedbar */
  speedbar: {

    /** kolko hodnot speedbaru sa bude generovat */
    valuesToGenerate: 85,

    /** default hodnota pre generovanie */
    valueDefault: 200,

    /** specialna hodnota pre generovanie */
    valueSpecial: {

      from: 199,
      to: 206,

      /** Kazda x-ta hodnota bude specialna */
      everyX: 8

    },

    /** Kazdy x-ty frame sa bude menit hodnota speedbaru */
    changeValueEveryX: 6

  },

  /** kolko musi byt opponent od hraca, aby sa aplikovala ina yCorrection */
  yPossitionCorrectionDistance: 10,

  /** yCorrection pre protihraca */
  yOpponentFarPosition: 0.02,

  /** ai config */
  ai: {
    /** ako casto sa ovplyvnuje speedbar */
    opponentSpeedBarUpdateFrames: [30, 30],
    opponentSpeedBarUpdateFramesLastLap: [10, 10],

    /** ako casto sa snazi dostat do lavej drahy */
    turnLeftFrequency: 45,

    speedBarConfig: {
      /** hodnoty mimo zavetria */
      noSlipstream: {
        min: 75,
        max: 80
      },
      /** hodnota v zavetri */
      slipstream: 90,
      /** hodnota sprintbaru na sprintRush */
      sprintRush: 90,
      /** hodnota pri ktorej sa v zavetri pokusi zmenit drahu */
      splipstreamValueToChange: 70,

      /** hodnota pri max reactionDistance */
      maxReactionDistance: {
        min: 90,
        max: 100
      },
      finalSprintSpeedbar: [
        {
          min: 85,
          max: 95
        },
        {
          min: 90,
          max: 98
        },
        {
          min: 95,
          max: 100
        },
        {
          min: 98,
          max: 100
        }
      ]

    },

    /** kedy zapnut/vypnut sprintRush */
    sprintRush: {
      on: 95,
      off: 60
    },

    /** max reactionDistance */
    maxReactionDistance: [
      15,
      10,
      5
    ],

    /** max reactionDistance pre boss fight */
    maxReactionDistanceBossFight: [
      5,
      5,
      5
    ]

  }

}
