<template>
  <section
    class="menu"
  >
    <menu-overlay
      v-if="showOverlay"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click.stop=""
    />
    <menu-icon-component
      v-show="showMenu"
      class="layout-menu pointer-events-auto"
      :exit-flashing="settingsOpenAutomatic"
      :toggle-automatic="settingsOpenAutomatic || keyOpenMenu"
      @on-toggle="onToggle"
      @settings-action="openSettings"
      @instructions-action="toggleInstructions(true)"
      @exit-action="exitOpen = true"
    />
    <instruction-component
      v-if="instructionsOpen"
      :is-mobile="isMobile"
      @close-instructions="toggleInstructions(false)"
    />
    <exit-discipline-modal
      v-if="exitOpen"
      :is-training="isTraining"
      :is-tutorial="isTutorial"
      class="pointer-events-auto"
      @yes="actionExit"
      @back="closeExit"
    />
    <settings-modal
      v-if="settingsOpen"
      class="pointer-events-auto"
      :show-graphics-settings="!isAppSsm"
      @close="closeSettings"
      @settings-change="setThings"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click.stop=""
    />
  </section>
</template>

<script lang="ts">
import {
  Menu,
  ExitDisciplineModal,
  SettingsModal,
  MenuOverlay
} from '@powerplay/core-minigames-ui-ssm'
import InstructionComponent from './InstructionComponent/index.vue'
import {
  game,
  requestManager,
  gameStats,
  settings,
  SettingsTypes,
  modes,
  tutorialManager
} from '@powerplay/core-minigames'
import { mapGetters } from 'vuex'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import store from '@/store'
import { defineComponent } from 'vue'
import { endManager } from '@/app/EndManager'

export default defineComponent({
  name: 'MenuSection',
  components: {
    MenuIconComponent: Menu,
    ExitDisciplineModal,
    SettingsModal,
    InstructionComponent,
    MenuOverlay
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    },
    isAppSsm: {
      type: Boolean,
      required: true
    },
    showOverlay: {
      type: Boolean,
      required: false
    }
  },
  emits: ['toggle-ui'],
  data() {

    return {
      settingsOpen: false,
      exitOpen: false,
      showExit: true,
      keyOpenMenu: false
    }

  },
  computed: {
    ...mapGetters({
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      instructionsOpen: 'InstructionsState/getShowInstructions',
      settingsOpenAutomatic: 'TutorialState/getSettings',
      exitPressed: 'InputsState/getExitPressed'
    }),
    showMenu() {

      return !(this.settingsOpen || this.exitOpen || this.instructionsOpen)

    },
    isTraining() {

      return modes.isTrainingMode()

    },
    isTutorial() {

      return modes.isTutorial()

    }
  },
  watch: {
    exitPressed(newValue) {

      if (newValue) {

        this.keyOpenMenu = !this.keyOpenMenu

        if (this.settingsOpen) {

          this.keyOpenMenu = false
          this.closeSettings()

        }
        if (this.exitOpen) {

          this.keyOpenMenu = false
          this.closeExit()

        }
        if (this.instructionsOpen) {

          this.keyOpenMenu = false
          this.toggleInstructions(false)

        }

      }

    }
  },
  methods: {
    actionExit(): void {

      // ak sme uz ukoncovali, tak potom redirectujeme
      if (gameStats.wasExitedGame()) {

        requestManager.redirect()
        return

      }

      gameStats.setExitedGame(true)
      endManager.prematureEnded = true
      this.exitOpen = false

      this.onToggle(this.exitOpen)
      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

      store.commit('GameState/SET_STATE', {
        isPrematureEnd: true
      })

      if (modes.isTutorial()) {

        store.commit('LoadingCircleState/SET_STATE', {
          isActive: true
        })

      }

    },
    closeExit(): void {

      this.exitOpen = false
      this.onToggle(this.exitOpen)

    },
    closeSettings(): void {

      this.settingsOpen = false
      this.setThings()
      settings.saveValues()
      tutorialManager.blockInputsManually(1000)
      this.onToggle(this.settingsOpen)

    },
    openSettings(): void {

      this.$store.commit('GameSettingsState/SET_STATE', {
        graphicsSettings: settings.getSetting(SettingsTypes.quality)
      })

      this.settingsOpen = true

    },
    toggleInstructions(value: boolean): void {

      this.$store.commit('InstructionsState/SET_INSTRUCTIONS_VISIBILITY', value)
      if (!value) {

        this.onToggle(value)

      }

    },
    setThings(): void {

      settings.update('quality', this.gameSettingsState.graphicsSettings)
      settings.update('sounds', this.gameSettingsState.volume ? 1 : 0)
      settings.update('qualityAuto', this.gameSettingsState.graphicsAuto ? 1 : 0)
      settings.update('isLeft', this.gameSettingsState.isLeft ? 1 : 0)
      settings.update('controlsType', this.gameSettingsState.controlsType)

    },
    onToggle(isOpen: boolean): void {

      if (this.settingsOpen || this.instructionsOpen || this.exitOpen) return
      this.keyOpenMenu = isOpen
      this.$emit('toggle-ui', this.keyOpenMenu)

      if (!isOpen) store.commit('TutorialState/SET_SETTINGS', false)

    }
  }
})

</script>

<style scoped lang="less">
.layout-menu {
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 1;
}
</style>
