import { THREE } from '@powerplay/core-minigames'
/**
 * Konfig pre paths
 */
export const pathsConfig = {

  /** pocet drah */
  tracks: 4,

  /** percenta pre pociatocne pozicie hracov */
  positionStartIntro: 0,

  /** percento pre pociatocne pozicie hracov - vsetci zacinaju v drahe 1 */
  positionStart: 0.772,

  /** v kolkom percente povolit inputy */
  startInputsPercent: 0.93,

  /** v kolkom percente sa zapne cas */
  startTimePercent: [0.2006, 0.2015, 0.2023, 0.203],

  /** v kolkom percente sa zapne cas */
  last100m: [0.6012, 0.6012, 0.6012, 0.6012],

  /** rozostupy pri startestartGap */
  startGap: -0.0087,

  /** kedy ma zacat finish faza podla % na krivke */
  positionsFinish: [
    1,
    1,
    1,
    1,
  ],

  /** sirka od jednej drahy k druhej - vypocitane */
  pathWidth: 1.229,

  /** nastavenia pre zmenu drahy */
  changePathConfig: {

    /** kolko percent dopredu a dozadu pozeram */
    percentDiff: 0.05,
    /** kolko bodov na jedno percento berieme na vypocet */
    pointsPerPercent: 10000,
    /** kolko framov trva zmenit drahu */
    framesToChange: 30,

    /** kolko m +- od miesta, kde sa chce hrac presunut nemoze nikto byt */
    blockingOffset: 1.7

  },

  /** ktory effekt zobrazovat 'arrows' 'round' 'glow' */
  blockingEffectType: 'round',

  /** percenta pre naklon */
  leanOnPercent: [
    {
      start: [0, 0, 0, 0],
      end: [0.30, 0.3015, 0.3030, 0.3045]
    },
    {
      start: [0.5, 0.5, 0.5036, 0.5043],
      end: [0.80, 0.8015, 0.8030, 0.8045]
    }
  ],

  /** ako velmi sa ma naklanat */
  leanOnAngles: {
    active: new THREE.Vector3(0, 0, -Math.PI / 16),
    inactive: new THREE.Vector3()
  },

  /** sekcie drahy podla zakrut */
  sections: [
    [0, 0, 0, 0],
    [0.38, 0.3815, 0.3788, 0.3794],
    [0.5, 0.5, 0.5036, 0.5043],
    [0.88, 0.8815, 0.8807, 0.8815]
  ]

}
