import type { TranslatesReplacements } from '@powerplay/core-minigames'

/**
 * Vymeny textov pre preklady - prekonkretnu disciplinu
 */
export const translatesReplacements: TranslatesReplacements[] = [

  {
    text: 'tutorialText14-9',
    replace: [
      {
        original: '[number]',
        new: '200'
      }
    ]
  }

]
