/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {

  athlete = 'athlete',
  env = 'env',
  envDynamic = 'envDynamic',
  trackLines = 'trackLines',
  skybox = 'SkyboxDay',
  helperMan = 'helperMan',
  finish = 'finish',
  cameraBoxes = 'cameraBoxes'

}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {

  athleteRacePrefix = 'athlete_race_',
  athleteRaceWhiteMan = 'male/athlete_race_white',
  athleteRaceMulattoMan = 'male/athlete_race_mulatto',
  athleteRaceBlackMan = 'male/athlete_race_black',
  athleteRaceWhiteWoman = 'female/athlete_race_white',
  athleteRaceMulattoWoman = 'female/athlete_race_mulatto',
  athleteRaceBlackWoman = 'female/athlete_race_black',
  athleteClothes = 'athlete_clothes',
  athleteClothesOpponent1 = 'athlete_clothes_opponent_1_v',
  athleteClothesOpponent2 = 'athlete_clothes_opponent_2_v',
  athleteClothesOpponent3 = 'athlete_clothes_opponent_3_v',
  athleteClothesOpponent4 = 'athlete_clothes_opponent_4_v',
  athleteClothesOpponent5 = 'athlete_clothes_opponent_5_v',
  bicycle = 'bicycle',
  bicycleOpponent1 = 'bicycle_opponent_1_v',
  bicycleOpponent2 = 'bicycle_opponent_2_v',
  bicycleOpponent3 = 'bicycle_opponent_3_v',
  bicycleOpponent4 = 'bicycle_opponent_4_v',
  bicycleOpponent5 = 'bicycle_opponent_5_v',
  audience = 'audience512v',
  colorGradient = 'ColorGradientAds2048v',
  colorGradientVshosting = 'ColorGradientAdsVshosting2048',
  concrete = 'CleanConcrete512',
  glassPanel = 'GlassPanel512',
  grass = 'Grass512',
  grassUncut = 'GrassUncut512',
  ground = 'GroundTexture512',
  woodFloor = 'WoodFloor1024',
  hexCladding = 'HexCladding512',
  lights = 'Lights512',
  metal = 'MetalDetail512',
  panel = 'Panel512',
  sand = 'Sand512',
  seats = 'Seats512v',
  stairs = 'Stairs512',
  texts = 'Texts1024v',
  lattice = 'Lattice512',
  plastic = 'PlasticDetail512',
  trapNet = 'TrapNet512',
  trees = 'Trees1024v',
  emissive = 'Emissive512',
  flagsImages = 'FlagsImages2048',
  flagsTexts = 'FlagsTexts2048v',
  trackTexts = 'TrackTexts1024',
  gradient = 'Gradient64',
  numbers = 'NumbersTemp512',
  npc = 'NPC512',
  npc1 = 'NPC512_1_v',
  npc2 = 'NPC512_2_v',
  npc3 = 'NPC512_3_v',
  camera = 'Camera128',
  lightFlashes = 'LightFlashes512',
  envLightmap = 'EnvLightmap2048v',
  skybox = 'Skybox1024',
  skyboxMountain = 'SkyboxMountain1024',
  skyboxClouds = 'SkyboxClouds1024',
  skyboxBuilding1 = 'SkyboxBuildings1_512',
  skyboxBuilding2 = 'SkyboxBuildings2_512',

}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {

  athlete = 'athlete',
  athleteOpponentPrefix = 'athlete_opponent_',
  athleteOpponent1 = 'athlete_opponent_1',
  athleteOpponent2 = 'athlete_opponent_2',
  athleteOpponent3 = 'athlete_opponent_3',
  athleteOpponent4 = 'athlete_opponent_4',
  athleteOpponent5 = 'athlete_opponent_5',
  bicycle = 'bicycle',
  bicycleOpponentPrefix = 'bicycle_opponent_',
  bicycleOpponent1 = 'bicycle_opponent_1',
  bicycleOpponent2 = 'bicycle_opponent_2',
  bicycleOpponent3 = 'bicycle_opponent_3',
  bicycleOpponent4 = 'bicycle_opponent_4',
  bicycleOpponent5 = 'bicycle_opponent_5',
  audience = 'audience',
  audienceFlags = 'audienceFlags',
  colorGradient = 'colorGradient',
  concrete = 'concrete',
  glassPanel = 'glassPanel',
  grass = 'grass',
  ground = 'ground',
  hexCladding = 'hexCladding',
  lights = 'lights',
  metal = 'metal',
  panel = 'panel',
  camera = 'camera',
  sand = 'sand',
  seats = 'seats',
  stairs = 'stairs',
  npc = 'npc',
  finish = 'finish',
  npcStart1 = 'npcStart1',
  npcStart2 = 'npcStart2',
  npcStart3 = 'npcStart3',
  texts = 'texts',
  texts2 = 'texts2',
  lattice = 'lattice',
  plastic = 'plastic',
  trapNet = 'trapNet',
  woodFloor = 'woodFloor',
  trees = 'trees',
  emissive = 'emissive',
  numbers = 'numbers',
  lightFlashes = 'lightFlashes',
  lightsGradient = 'lightGradient',
  flagsImages1 = 'FlagsImages1',
  flagsImages2 = 'FlagsImages2',
  flagsImages3 = 'FlagsImages3',
  flagsImages4 = 'FlagsImages4',
  flagsImages5 = 'FlagsImages5',
  flagsImages6 = 'FlagsImages6',
  flagsImages7 = 'FlagsImages7',
  flagsImages8 = 'FlagsImages8',
  flagsTexts1 = 'FlagsTexts1',
  flagsTexts2 = 'FlagsTexts2',
  flagsTexts3 = 'FlagsTexts3',
  flagsTexts4 = 'FlagsTexts4',
  flagsTexts5 = 'FlagsTexts5',
  flagsTexts6 = 'FlagsTexts6',
  flagsTexts7 = 'FlagsTexts7',
  flagsTexts8 = 'FlagsTexts8',
  flagsGradient = 'FlagsGradient',
  onlyVertexColors = 'onlyVertexColors',
  leadIndicator = 'leadIndicator',
  skybox = 'Skybox',
  skyboxMontain = 'skyboxMontain',
  skyboxClouds = 'skyboxClouds',
  skyboxBuilding1 = 'skyboxBuilding1',
  skyboxBuilding2 = 'skyboxBuilding2',

}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {

  prepare1 = 'prepare 1',
  prepare2 = 'prepare 2',
  prepare3 = 'prepare 3',
  prepare4 = 'prepare 4',
  prepare5 = 'prepare 5',
  prepare6 = 'prepare 6',
  prepareIdle = 'prepare idle',
  afterFinish = 'after finish',
  happy1 = 'happy 1',
  happy2 = 'happy 2',
  happy3 = 'happy 3',
  happy4 = 'happy 4',
  happy5 = 'happy 5',
  sad1 = 'sad 1',
  sad2 = 'sad 2',
  sad3 = 'sad 3',
  lookBackR = 'look back R',
  sprint = 'sprint',
  tempo = 'tempo',

}

/** Mena vsetkych animacii bicykla */
export enum BicycleAnimationsNames {

  prepare = 'bike prepare',
  rotate = 'bike rotate',
  starting = 'bike starting'

}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  nothing: unknown

}

/** Farby pre lidra */
export enum LeaderColors {

  green = 0,
  lightBlue = 1

}

/** Rozne eventy */
export enum CustomGameEvents {
  playerFinished = 'playerFinished'
}

/** Typy sipok pre najblizsich superov */
export enum ArrowTypes {
  none = 'none',
  green = 'green',
  red = 'red'
}

/** Data pre sipku pre najblizsich superov */
export interface ArrowData {
  type: string,
  value: number
}
