import { Sides } from '@/app/types'
import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface BlockingState {
  side: Sides | undefined,
  isVisible: boolean,
  leanOn: number
}

const initialState = () => ({
  side: Sides.LEFT,
  isVisible: false
})

const blockingState: Module<BlockingState, RootState> = {
  state: initialState(),
  getters: {
    getBlockingState: (moduleState: BlockingState) => moduleState,
    getBlockingLeanOn: (moduleState: BlockingState) => moduleState.leanOn
  },
  mutations: {
    RESET: (moduleState: BlockingState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: BlockingState, newState: BlockingState) => {

      moduleState.side = newState.side
      moduleState.isVisible = newState.isVisible

    },
    SET_LEAN_ON: (moduleState: BlockingState, leanOn: number) => {

      moduleState.leanOn = leanOn

    }
  },
  namespaced: true
}

export default blockingState
