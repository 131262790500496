import {
  sprintConfig,
  velocityConfig
} from '@/app/config'
import type { Athlete } from '.'
import { PlayerStates } from '@/app/types'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { playersManager } from '@powerplay/core-minigames'

/**
 * Trieda pre spravu sprintu hraca
 */
export class AthleteSprintBarManager {

  /** Minimalna hodnota baru */
  public readonly MIN_VALUE = 0

  /** Maximalna hodnota baru */
  public readonly MAX_VALUE = 100

  /** hodnota sprint baru */
  public sprintBarValue = sprintConfig.sprintBarDefaultValue

  /** counter sprint frame rate */
  private sprintBarFrameRateCount = 0

  /** counter sprint on 0 frame */
  private sprintOnZeroFrameCount = 0

  /** Hodnota poklesu speed baru */
  private sprintBarDecreaseRate = 0

  /** stav naplnania sprintbaru */
  public sprintBarFilling = 0

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Nastavenie hodnoty poklesu speed baru
   */
  public setSprintBarDecreaseRate(): void {

    const strengthOpp = playersManager.getPlayerById(this.athlete.uuid)?.attribute.total || 0
    const strengthPlayer = playersManager.getPlayer()?.attribute.total || 0
    const diff = strengthOpp - strengthPlayer

    let index = 0
    if (!this.athlete.playable) {

      if (diff > 200) {

        index = 3

      } else if (diff > 100) {

        index = 2

      } else if (diff > 60) {

        index = 1

      }

    }

    this.sprintBarDecreaseRate = sprintConfig.sprintBarDecreaseRates[index]
    console.log('sprintBarDecreaseRate: ', this.sprintBarDecreaseRate, this.athlete.uuid, index, diff)

  }

  /**
   * Aktualizacia veci
   * @param speedBarValue - hodnota speed baru
   * @param sprintThreshold - hranica pre sprint
   */
  public update(speedBarValue: number, sprintThreshold: number): void {

    this.sprintBarFrameRateCount += 1
    const {
      maxSpeedBarDecrease,
      sprintOnZeroFrameRate,
      sprintBarFillRate,
      sprintBarFrameRate,
      slipStreamCoef
    } = sprintConfig

    if (this.sprintBarFrameRateCount >= sprintBarFrameRate) {

      this.sprintBarFrameRateCount = 0
      let sprintBarChange = 0
      this.sprintBarFilling = 0

      if (speedBarValue <= sprintThreshold) {

        this.athlete.isSprinting = false
        if (!this.athlete.isEnd && this.athlete.isState(PlayerStates.sprint)) {

          this.athlete.setState(PlayerStates.tempo)

        }
        sprintBarChange = (sprintThreshold - speedBarValue) / 160 + sprintBarFillRate
        this.sprintBarFilling = 1
        if (this.athlete.isInSlipStream) {

          sprintBarChange *= slipStreamCoef
          this.sprintBarFilling = 2

        }

      } else {

        this.athlete.isSprinting = true
        if (!this.athlete.isEnd && this.athlete.isState(PlayerStates.tempo)) {

          this.athlete.setState(PlayerStates.sprint)

        }
        sprintBarChange = ((speedBarValue - sprintThreshold) / 20 + this.sprintBarDecreaseRate) * -1
        this.sprintBarFilling = -2
        if (this.athlete.isInSlipStream) {

          sprintBarChange /= slipStreamCoef
          this.sprintBarFilling = -1

        }

      }
      this.sprintBarValue += sprintBarChange

    }
    this.sprintBarValue = Math.min(this.sprintBarValue, this.MAX_VALUE)
    this.sprintBarValue = Math.max(this.sprintBarValue, this.MIN_VALUE)
    if (this.sprintBarValue <= 0) {

      this.sprintOnZeroFrameCount += 1

    }
    if (this.sprintOnZeroFrameCount >= sprintOnZeroFrameRate) {

      this.sprintOnZeroFrameCount = 0
      this.athlete.speedBarManager.speedBarMax -= maxSpeedBarDecrease
      const { speedbarMaxMinimum } = velocityConfig.speedBar
      this.athlete.speedBarManager.speedBarMax = Math.max(speedbarMaxMinimum, this.athlete.speedBarManager.speedBarMax)

    }

    if (this.athlete.playable) {

      audioHelper.manageHeartbeat(this.athlete.speedBarManager.getSpeedPower() > 0 && this.sprintBarValue <= 0)

    }

  }

}
