import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import {
  BicycleAnimationsNames,
  PlayerAnimationsNames
} from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.prepare1]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.prepare2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare3]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare4]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare5]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare6]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepareIdle]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.afterFinish]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy3]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy4]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy5]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.sad1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.sad2]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.sad3]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.lookBackR]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.sprint]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.tempo]: {
    loop: true,
    starting: false
  },

}

/**
 * Konfig pre animacie bicykla
 */
export const animationsConfigBicycle: AnimationsSettingRepository = {

  [BicycleAnimationsNames.prepare]: {
    loop: true,
    starting: true
  },
  [BicycleAnimationsNames.rotate]: {
    loop: true,
    starting: false
  },
  [BicycleAnimationsNames.starting]: {
    loop: true,
    starting: false
  }

}
