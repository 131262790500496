import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface SpeedBarState {
  speedPower: number,
  mark: number,
  speedColor: string,
  speedBarMax: number,
  sprintBarValue: number,
  isSlipStream: boolean,
  sprintBarFilling: number,
  speed: number
}

const initialState = () => ({
  speedPower: 0,
  mark: 80,
  speedColor: '',
  speedBarMax: 100,
  sprintBarValue: 100,
  isSlipStream: false,
  sprintBarFilling: 0,
  speed: 0
})

const speedBarState: Module<SpeedBarState, RootState> = {
  state: initialState(),
  getters: {
    getSpeedBarState: (moduleState: SpeedBarState) => moduleState,
  },
  mutations: {
    RESET: (moduleState: SpeedBarState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: SpeedBarState, newState: SpeedBarState) => {

      if (newState.speedPower !== undefined) moduleState.speedPower = newState.speedPower
      if (newState.mark !== undefined) moduleState.mark = newState.mark
      if (newState.speedColor !== undefined) moduleState.speedColor = newState.speedColor
      if (newState.speedBarMax !== undefined) moduleState.speedBarMax = newState.speedBarMax
      if (newState.sprintBarValue != undefined) moduleState.sprintBarValue = newState.sprintBarValue
      if (newState.isSlipStream != undefined) moduleState.isSlipStream = newState.isSlipStream
      if (newState.sprintBarFilling != undefined) moduleState.sprintBarFilling = newState.sprintBarFilling
      if (newState.speed != undefined) moduleState.speed = newState.speed

    },
  },
  namespaced: true
}

export default speedBarState
