<template>
  <div
    id="app"
    class="app"
  >
    <div style="z-index: 1000000">
      <div
        v-show="!modalActive && !trainingState.firstTutorialMessage"
      >
        <countdown-component />
        <hud-component v-show="debugState.isHudActive" />
        <error-modal
          class="pointer-events-auto"
          @close="closeApp"
        />
        <blocking-effect-component />
        <emotion-messages />
        <phase-start />
        <start-debug-bar />
        <under-menu-component />
        <info-component />
        <mobile-inputs
          v-if="isMobile"
        />
        <efficiency-component :is-mobile="isMobile" />
        <speed-meter-component />
        <training-layout v-if="uiState.showTrainingLayout" />
        <training-results v-if="trainingResultsState.showResults" />
        <data-table />
        <black-overlay />
        <lap-component />
        <arrow-distances />
      </div>
      <text-message-component />
      <fps-component />
      <info-keys v-show="!modalActive && !isMobile" />
      <tutorial-logic-component v-show="!modalActive" />
      <tutorial-info-keys v-show="!modalActive && !isMobile && isTutorial" />
      <menu-section
        v-if="showMenu && !trainingState.firstTutorialMessage"
        :is-mobile="isMobile"
        style="pointer-events: auto"
        :is-app-ssm="isAppSSM2023"
        :show-overlay="modalActive"
        @toggle-ui="toggleUi"
      />
      <color-overlay-component
        v-if="isBlurActive && !blurState.isToggle"
        :opacity="0"
      />
      <loading-circle-overlay />
      <training-override
        style="pointer-events: auto"
        @mousedown.stop=""
        @mouseup.stop=""
        @touchstart.stop=""
        @touchend.stop=""
      />
    </div>
    <loading />
    <white-shine-component />
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'

// Internal Package codes
import {
  game,
  MobileDetector,
  requestManager,
  modes,
  inputsManager
} from '@powerplay/core-minigames'
import {
  ErrorModal,
  LoadingCircleOverlay,
  ColorOverlayComponent,
} from '@powerplay/core-minigames-ui-ssm'

// Component codes

// Debug Components
import HudComponent from '@/components/DebugComponents/HUD.vue'
// import DirectionsInfo from '@/components/DebugComponents/DirectionsInfo.vue'

// Game Components
import MobileInputs from '@/components/Inputs/MobileInputs.vue'
import PhaseStart from '@/components/PhaseStart.vue'
import DataTable from '@/components/Table/DataTable.vue'
import Loading from '@/components/Loading.vue'
import InfoComponent from '@/components/InfoComponent.vue'
import MenuSection from '@/components/MenuSection/index.vue'
import EfficiencyComponent from '@/components/Inputs/EfficiencyComponent.vue'
import FpsComponent from '@/components/FpsComponent.vue'
import SpeedMeterComponent from '@/components/SpeedMeterComponent.vue'
import TextMessageComponent from '@/components/TextMessageComponent.vue'
import TrainingLayout from '@/components/Training/TrainingLayout.vue'
import TrainingResults from '@/components/Training/TrainingResults.vue'
import UnderMenuComponent from '@/components/UnderMenuComponent/index.vue'
import TutorialLogicComponent from '@/components/Tutorial/TutorialLogicComponent.vue'
import CountdownComponent from '@/components/CountdownComponent/index.vue'
import TutorialInfoKeys from '@/components/Tutorial/TutorialInfoKeys.vue'
import TrainingOverride from '@/components/Training/TrainingOverride.vue'
import WhiteShineComponent from '@/components/WhiteShineComponent.vue'
import StartDebugBar from '@/components/StartDebugBar.vue'
import BlackOverlay from '@/components/BlackOverlay.vue'
import EmotionMessages from '../EmotionMessages.vue'

// Internal Codes
import { initiateListeners } from '@/helpers/initiateListeners'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { endManager } from '@/app/EndManager'

import { defineComponent } from 'vue'
import { startPhaseStateManager } from '@/app/phases/StartPhase/StartPhaseStateManager'
import LapComponent from '@/components/LapComponent.vue'
import InfoKeys from '../InfoKeys.vue'
import BlockingEffectComponent from '../BlockingEffectComponent.vue'
import ArrowDistances from '../ArrowDistances.vue'

export default defineComponent({
  name: 'StructureComponent',
  components: {
    HudComponent,
    // DirectionsInfo,
    Loading,
    MobileInputs,
    PhaseStart,
    MenuSection,
    ErrorModal,
    DataTable,
    InfoComponent,
    EfficiencyComponent,
    SpeedMeterComponent,
    FpsComponent,
    TextMessageComponent,
    TrainingLayout,
    TrainingResults,
    UnderMenuComponent,
    TutorialLogicComponent,
    CountdownComponent,
    LoadingCircleOverlay,
    TutorialInfoKeys,
    TrainingOverride,
    ColorOverlayComponent,
    WhiteShineComponent,
    StartDebugBar,
    BlackOverlay,
    EmotionMessages,
    LapComponent,
    InfoKeys,
    BlockingEffectComponent,
    ArrowDistances
  },
  data() {

    return {
      modalActive: false,
      isAppSSM2023: false
    }

  },
  computed: {
    ...mapGetters({
      uiState: 'UiState/getUiState',
      debugState: 'DebugState/getDebugState',
      showTrainingResults: 'TrainingResultsState/getTrainingResultsShow',
      finishTopBox: 'FinishTopBoxState/getfinishTopBoxState',
      trainingState: 'TrainingState/getTrainingState',
      isBlurActive: 'BlurState/getIsActive',
      blurState: 'BlurState/getBlurState',
      trainingResultsState: 'TrainingResultsState/getTrainingResultsState',
      textMessageState: 'TextMessageState/getTextMessageState',
    }),
    isMobile(): boolean {

      return MobileDetector.isMobile()

    },
    isTutorial(): boolean {

      return modes.isTutorial()

    },
    showMenu(): boolean {

      return !this.showLoading() && !this.showTrainingResults

    },
    textMessageStyle(): unknown {

      return {
        position: 'absolute',
        left: '50%',
        top: '20%',
        'transform-origin': 'top'
      }

    },
  },
  watch: {
    isBlurActive(newState) {

      if (newState) {

        document.getElementsByTagName('canvas')[0].classList.add('blur-class')

      } else {

        document.getElementsByTagName('canvas')[0].classList.remove('blur-class')

      }

      // osetrenie vypnutia menu pocas zobrazenej tabulky
      if (!newState && this.blurState.isTable) {

        this.$store.commit('BlurState/SET_IS_ACTIVE', true)

      }

    }
  },
  created(): void {

    this.$store.commit('LoadingState/SET_STATE', {
      showLoading: true,
      loadingProgress: 0
    })

    this.isAppSSM2023 = modes.isAppWSM2021()
    initiateListeners(this)
    tutorialUIChange.registerVM(this)

  },
  methods: {
    showLoading(): boolean {

      return this.$store.getters['LoadingState/getLoadingState'].showLoading

    },
    closeApp(): void {

      endManager.prematureEnded = true
      this.$store.commit('LoadingCircleState/SET_STATE', {
        isActive: true
      })
      requestManager.redirect(true)

    },
    toggleUi(toggle = true) {

      if (toggle) {

        this.$store.commit('BlurState/SET_IS_ACTIVE', true)
        this.$store.commit('BlurState/SET_IS_TOGGLE', true)
        inputsManager.actionInputsBlocked = true
        this.modalActive = true
        game.pauseGame()

      } else {

        inputsManager.actionInputsBlocked = false
        this.modalActive = false
        this.$store.commit('BlurState/SET_IS_ACTIVE', false)
        this.$store.commit('BlurState/SET_IS_TOGGLE', false)
        game.resumeGame()

      }

    },
    finishedFirstLine() {

      startPhaseStateManager.hideTextMessage(1)

    },
    finishedSecondLine() {

      startPhaseStateManager.hideTextMessage(2)

    },
  }
})
</script>

<style>
.blur-class {
  filter: blur(4px);
}
</style>
<style lang="less" scoped>
.app {
  min-height: 100%;
  min-width: 100%;
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.absolute {
  position: absolute;
}

.midder {
  top: 50%;
  left: 50%;
}
</style>
