import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(-58.72128677368164, 12.860137939453125, -4.4567999839782715),
      endPosition: new THREE.Vector3(-51.11765670776367, 7.782679557800293, -14.10534381866455),
      startRotation: new THREE.Vector3(1.6677861732124133, -0.3621488360871821, 1.6995397779737569),
      endRotation: new THREE.Vector3(1.7394027282594748, -0.18534816685433209, 2.1097627058513213),
      duration: 3,
      notSkippableFrames: 20,
      fov: 85
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(25.0108642578125, 1.7071459293365479, 24.008968353271484),
      endPosition: new THREE.Vector3(-2.639317274093628, 4.572782516479492, 25.520973205566406),
      startRotation: new THREE.Vector3(1.2327887778572897, 0.03464602517905235, -1.980777227329393),
      endRotation: new THREE.Vector3(1.4643427570970826, -0.03422541094737597, -1.57486974009377),
      duration: 3,
      notSkippableFrames: 20,
      fov: 48
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(-17.553674697875977, 1.6311092376708984, 26.99440574645996),
      endPosition: new THREE.Vector3(-6.966292381286621, 1.8096580505371094, 26.94769859313965),
      startRotation: new THREE.Vector3(1.5709475733837353, 0.0060364227749000075, 1.5539439334268423),
      endRotation: new THREE.Vector3(1.5708151652485536, 0.021748187985792342, 1.5722704084072239),
      fov: 54.5,
      duration: 5
    },
    [CameraStates.disciplineIntro]: {
      startPosition: new THREE.Vector3(-8.20163428, 1.3392995581, 0.37076743),
      endPosition: new THREE.Vector3(-5.370808, 1.27990091, 0.223722456),
      startRotation: new THREE.Vector3(1.5700448403224243, -0.016752746437589318, 1.5223709134100865),
      endRotation: new THREE.Vector3(1.5703778525076457, -0.008899140301990828, 1.5171237648503846),
      duration: 3,
      fov: 34.2,
      fovEnd: 45.4,
      offsetCamera: true
    },
    [CameraStates.disciplineIntroSecond]: {
      startPosition: new THREE.Vector3(-8.12626314163208, 0.7892749965190887, -22.58235816955566),
      endPosition: new THREE.Vector3(-10.789407730102539, 2.381941080093384, -22.71455192565918),
      startRotation: new THREE.Vector3(1.650161904706685, 0.1545784594999942, 0.9291064499820968),
      endRotation: new THREE.Vector3(1.5145811294588816, -0.0541939114092237, 1.1384918397653212),
      duration: 3,
      fov: 53
    },
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      // startPosition: new THREE.Vector3(-2, 2, 3),
      startPosition: new THREE.Vector3(6, 2, 0),
      // endPosition: new THREE.Vector3(2, 2, 3),
      endPosition: new THREE.Vector3(6, 2, 0),
      duration: 10,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5,
  dollyZoom: {

    // maximalny mozny fov pri najvyssej rychllosti
    maxFov: 70,

  },
  // specialna kamera na zaciatku z boku a potom ide do normalnej
  specialCameraStart: {

    // kolko sekund sa bude cakat v pohlade z boku na zmenu pohladu do normalneho
    delayToChange: 1,

    // kolko sekund bude prebiehat zmena pohladu do normalneho
    changeDuration: 2,

    // vektor pre zmenu ideals na pohlad zboku
    ideals: new THREE.Vector3(3, 1.5, 0)

  },
  // efekt zatrasenia pri blocking efekte
  shake: {

    // koeficient spomalenia kazdy dalsi bounce krok
    coefSlowDown: 0.7,

    // kolkokrat sa spravi dalsi bounce krok
    parts: 5,

    // aky je step v prvom bounce kroku (neskor sa zvacsuje cez coefSlowDown - deli sa nim)
    step: 0.05 / 2,

    // aky velky je prvy posun pri efekte (neskor sa zmensuje cez coefSlowDown - nasobi sa nim)
    firstOffsetLimit: 0.05,

    // pocet frameov, kolko nepojde efekt po sebe (pocita sa az po dokonceni efektu)
    freezeFrames: 20,

    // ci je trasenie povolene
    enabled: true

  },

  // cielova kamera
  finish: {

    // Hodnota pre zoom
    zoom: 8,

    // Staticka pozicia kamery v cieli
    positionCamera: new THREE.Vector3(-34.831, 2, 2.81),

    // Delay vo frameoch, kedy sa az zobrazi tato kamera
    delayFrames: 25

  }

}
