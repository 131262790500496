import store from '@/store'
import {
  type DisciplinePhaseManager,
  Sides,
  TutorialEventType,
  AudioNames,
  Tasks,
  AudioGroups,
  ArrowTypes,
  type ArrowData
} from '../../types'
import {
  audioManager,
  cameraManager,
  gsap,
  fpsManager,
  modes
} from '@powerplay/core-minigames'
import { player } from '@/app/entities/athlete/player'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { inputsManager } from '@/app/InputsManager'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import { trainingTasks } from '@/app/modes/training'
import {
  cameraConfig,
  gameConfig,
  trainingConfig
} from '@/app/config'
import { endManager } from '@/app/EndManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import type { Athlete } from '@/app/entities/athlete'

/**
 * Trieda fazy pre zjazd
 */
export class RunningPhase implements DisciplinePhaseManager {

  /** speed lock */
  public speedLockActive = false

  /** koeficient na nasobenie rychlosti a casov pri speedLocku */
  public speedLockCoef = 1

  /** Ci je dokoncena transformacia kamery v speed locku */
  public speedLockCameraTransformationComplete = false

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /** Ci je aktivna faza */
  public isActive = false

  /** blocker zmeny drahy */
  public switchLaneBlocker = false

  /** ci uz maju mat aktivne rychlosti a odbocovania */
  public runStarted = false

  /** frame counter */
  private frameCounter = 0

  /** typ countdown hlasky */
  private countdownMessageType = 0

  /** vypnutie countdownu */
  private countdownFinished = false

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    if (modes.isTutorial()) this.frameCounter = -1
    this.reset()
    console.warn('game phase started')
    this.isActive = true
    startPhaseStateManager.enableInputs(true)
    audioManager.play(AudioNames.audienceRace)
    audioManager.stopAudioByName(AudioNames.audienceIntro)
    audioManager.stopAudioByName(AudioNames.audienceIntroduction)
    if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

      audioManager.play(AudioNames.commentatorStart)

    }

    this.changeCameraOnStart()

  }

  /**
   * Zmena kamery na zaciatku (pohlad zboku a potom plynulo na hlavnu kameru)
   */
  private changeCameraOnStart(): void {

    cameraManager.setTLookAtCoef(1)

    const { ideals, changeDuration, delayToChange } = cameraConfig.specialCameraStart
    const cameraOffsetOrig = player.worldEnvLinesManager.actualIdealOffset.clone()
    const cameraOffsetTransition = ideals.clone()
    cameraManager.changeIdeals(ideals)
    gsap.to(cameraOffsetTransition, {
      delay: delayToChange,
      duration: changeDuration,
      x: cameraOffsetOrig.x,
      z: cameraOffsetOrig.z,
      y: cameraOffsetOrig.y,
      onUpdate: () => {

        cameraManager.changeIdeals(cameraOffsetTransition)

      },
      onComplete: () => {

        cameraManager.changeIdeals(cameraOffsetOrig)

      }
    })

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    if (this.runStarted && player.isInSlipStream) {

      if (this.frameCounter % 3 === 0) {

        trainingTasks.countTaskValue(Tasks.splitStream, trainingConfig.slipStreamPercent)

      }
      tutorialFlow.inSlipStream()

    }
    this.manageCountdown()
    this.frameCounter += 1
    this.manageAthletesDistances()

    if (this.switchLaneBlocker) return
    if (inputsManager.moveDirectionLeft || store.getters['MovementState/getPositionX'] < 0) {

      this.switchLaneBlocker = true
      player.changePath(Sides.LEFT)

    }
    if (inputsManager.moveDirectionRight || store.getters['MovementState/getPositionX'] > 0) {

      this.switchLaneBlocker = true
      player.changePath(Sides.RIGHT)

    }

  }

  /**
   * Riesenie konkretnej sipky pre najblizsieho supera
   * @param athletes - atleti v danej drahe
   * @returns Data pre sipku
   */
  private manageArrow(athletes: Athlete[]): ArrowData {

    const data = {
      type: ArrowTypes.none,
      value: 0
    }

    athletes.forEach((athlete) => {

      if (data.type === ArrowTypes.none) {

        // diff davame menej o 1 meter kvoli vypoctom, kedze pivot je v strede bicykla
        const diff = athlete.reactionDistance - player.reactionDistance - 1
        if (diff > gameConfig.arrowDistances.limitGreen) {

          data.type = ArrowTypes.green
          data.value = Math.round(diff)

        } else if (diff >= gameConfig.arrowDistances.limitToShow) {

          data.type = ArrowTypes.red
          data.value = Math.round(diff)

        }

      }

    })

    return data

  }

  /**
   * Najde a nastavi reaction time pre vsetkych cyklistov
   */
  private manageAthletesDistances(): void {

    if (this.frameCounter % gameConfig.distancesManagementFrequency !== 0) return

    const opponents = opponentsManager.getOpponents()

    const athletes = [...opponents, ...[player]]


    athletes.sort((a, b) => {

      if (a.worldEnvLinesManager.lap < b.worldEnvLinesManager.lap) return 1
      if (a.worldEnvLinesManager.lap > b.worldEnvLinesManager.lap) return -1
      if (a.worldEnvLinesManager.actualSectionIndex < b.worldEnvLinesManager.actualSectionIndex) return 1
      if (a.worldEnvLinesManager.actualSectionIndex > b.worldEnvLinesManager.actualSectionIndex) return -1
      if (a.worldEnvLinesManager.percentOfActualSection < b.worldEnvLinesManager.percentOfActualSection) return 1
      return -1

    })
    const percentOfFirst = athletes[0].worldEnvLinesManager.getActualPercent() + athletes[0].worldEnvLinesManager.lap
    const opponentsInLeftTrack: Athlete[] = []
    const opponentsInSameTrack: Athlete[] = []
    const opponentsInRightTrack: Athlete[] = []
    const playerTrackIndex = player.worldEnvLinesManager.pathIndex

    athletes.forEach((athlete, index) => {

      athlete.reactionDistance =
        (percentOfFirst - (athlete.worldEnvLinesManager.getActualPercent() + athlete.worldEnvLinesManager.lap)) /
        athlete.worldEnvLinesManager.oneMeterInPercent
      if (athlete.playable) {

        if (index < athlete.provisionalPosition) {

          trainingTasks.countTaskValue(Tasks.overtaking, athlete.provisionalPosition - index)
          if (player.worldEnvLinesManager.lap === 3) {

            endManager.overtakingCount += 1

          }

        }

      } else {

        const oppTrackIndex = athlete.worldEnvLinesManager.pathIndex
        if (playerTrackIndex - 1 === oppTrackIndex) opponentsInLeftTrack.push(athlete)
        if (playerTrackIndex === oppTrackIndex) opponentsInSameTrack.push(athlete)
        if (playerTrackIndex + 1 === oppTrackIndex) opponentsInRightTrack.push(athlete)

      }
      athlete.provisionalPosition = index

    })

    // poriesenie sipok
    const leftArrow = this.manageArrow(opponentsInLeftTrack)
    const sameArrow = this.manageArrow(opponentsInSameTrack)
    const rightArrow = this.manageArrow(opponentsInRightTrack)

    store.commit('ArrowDistancesState/SET_STATE', {
      leftType: leftArrow.type,
      leftValue: leftArrow.value,
      middleType: sameArrow.type,
      middleValue: sameArrow.value,
      rightType: rightArrow.type,
      rightValue: rightArrow.value,
      limitToShow: gameConfig.arrowDistances.limitToShow
    })

  }

  /**
   * Inputy pri behu
   */
  public handleInputs(): void {

    if (!this.isActive) return
    player.speedBarManager.handleInputs()

  }

  /**
   * Zacatie preteku - spusta iba prvy cyklista, ktory prejde cez tenku ciaru
   */
  public startRun(): void {

    this.switchLaneBlocker = false
    this.runStarted = true
    opponentsManager.getOpponents().forEach(opponent => {

      opponent.speedBarManager.inputsBlocked = false

    })
    player.speedBarManager.inputsBlocked = false

    store.commit('LapPositionState/SET_SHOW_LAP', true)
    store.commit('LapPositionState/SET_SHOW_POSITION', true)
    startPhaseStateManager.enableInputs()
    store.commit('GamePhaseState/SET_SHOW', true)
    store.commit('ArrowDistancesState/SET_SHOW', true)

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    this.isActive = false
    this.speedLockCameraTransformationComplete = false
    console.warn('game phase ended')
    this.speedLockActive = false
    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * Vysetrenie, ci treba instalerp pri starte
   * @returns ci treba instalerp
   */
  public needsInstalerpForStart(): boolean {

    return this.frameCounter <= 5

  }

  /**
   * Odpocitavanie pred startom
   */
  private manageCountdown(): void {

    if (this.countdownFinished || this.frameCounter < 0) return
    if (((this.frameCounter - 1) % fpsManager.fpsLimit) === 0) {

      if (this.countdownMessageType >= 4) {

        this.countdownFinished = true
        tutorialFlow.eventActionTrigger(TutorialEventType.start)
        return

      }
      store.commit('TextMessageState/SET_SHOW_COUNTDOWN', true)
      store.commit('TextMessageState/SET_SHOW_TYPE', this.countdownMessageType)
      if (this.countdownMessageType >= 3) {

        this.startRun()

      }
      this.countdownMessageType += 1

    }

  }

  /**
   * reset
   */
  public reset(): void {

    this.isActive = false
    this.switchLaneBlocker = false
    this.runStarted = false
    this.frameCounter = 0
    this.countdownMessageType = 0
    this.countdownFinished = false
    if (modes.isTutorial()) this.frameCounter = -1

  }

}
