import {
  cameraConfig,
  playerAnimationConfig
} from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  BicycleAnimationsNames,
  DisciplinePhases,
  EmotionTypesFinish,
  PlayerAnimationsNames,
  PlayerStates
} from '@/app/types'
import type { Athlete } from './Athlete'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import {
  CallbackAnimationTypes,
  gsap,
  THREE
} from '@powerplay/core-minigames'

/**
 * Dedikovany manazer animacii pre hraca/superov.
 */
export class AthleteAnimationManager {

  /** ci sme uz nastavili podla stavov veci */
  private statesActive = {
    [PlayerStates.prepare]: false,
    [PlayerStates.starting]: false,
    [PlayerStates.tempo]: false,
    [PlayerStates.sprint]: false,
    [PlayerStates.lookBack]: false,
    [PlayerStates.end]: false
  }

  /** aktualna animacia - TODO rozsir o vsetky animacie mimo konecnej */
  public actualAnimation?: PlayerAnimationsNames

  /** ci sme nastavili konecnu animaciu */
  public isEndEmotionSet = false

  /** vaha animacie tempo voci sprint */
  private tempoWeight = 0

  /** isStarting */
  private isStarting = false

  /** vaha animacie lookBack */
  private lookBackWeight = 2

  /** pocitadlo, kolkokrat uz bola prehrata animacia prepare */
  private counterPrepareAnimations = 0

  /** pocitadlo, na oddialenie sad animacie */
  private counterDelaySad = -1

  /** ktoru prepare animaciu bude mat */
  public prepareAnimation = PlayerAnimationsNames.prepare1

  /** prepareAnimationIdleTween */
  private prepareAnimationIdleTween?: gsap.core.Tween

  /** Posledna animacia prepare crossfade-u pociatocna */
  private lastPrepareCrossfadeAnimationFrom = PlayerAnimationsNames.prepare1

  /** Posledna animacia prepare crossfade-u konecna */
  private lastPrepareCrossfadeAnimationTo = PlayerAnimationsNames.prepare2

  /** sadAnimationIdleTween */
  public sadAnimationIdleTween?: gsap.core.Tween

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    this.athlete.animationsManager.setSpeed(speed)
    this.athlete.bicycle.animationsManager.setSpeed(speed)

  }

  /**
   * Reset rychlosti animacii
   */
  private resetAnimationSpeed() {

    this.athlete.animationsManager.resetSpeed()

  }

  /**
   * Start animacie prepare
   * @param crossfadeTime - Cas, za ktory sa vykona crossfade
   */
  public startPrepareAnimationIdle(crossfadeTime = playerAnimationConfig.defaultCrossfadeTime): void {

    if (!this.statesActive[PlayerStates.prepare]) return

    this.changeAnimationSpeed(0.3)
    if (this.actualAnimation) this.lastPrepareCrossfadeAnimationFrom = this.actualAnimation
    this.lastPrepareCrossfadeAnimationTo = PlayerAnimationsNames.prepareIdle
    this.athlete.animationsManager.crossfadeTo(
      PlayerAnimationsNames.prepareIdle,
      crossfadeTime,
      true,
      false
    )

    const duration = THREE.MathUtils.randFloat(0.05, 0.15)
    this.prepareAnimationIdleTween = gsap.to({}, {
      onComplete: () => {

        this.startPrepareAnimation()

      },
      duration: crossfadeTime + duration
    })

  }

  /**
   * Nastavenie dalsej animacie prepare
   */
  private setNextAnimationPrepare(): void {

    this.prepareAnimation = playerAnimationConfig
      .prepareAnimationsChains[this.athlete.animationPrepareChainIndex][this.counterPrepareAnimations]

  }

  /**
   * Start prepare animacie prepare
   * @param crossfadeTime - Cas, za ktory sa vykona crossfade
   */
  private startPrepareAnimation(crossfadeTime = playerAnimationConfig.defaultCrossfadeTime): void {

    if (!this.statesActive[PlayerStates.prepare]) return

    if (this.counterPrepareAnimations > 0) {

      // uz to je druhy a dalsi raz, takze davame dalsiu animaciu prepare
      this.setNextAnimationPrepare()

    }

    this.athlete.animationsManager.addAnimationCallback(
      this.prepareAnimation,
      CallbackAnimationTypes.loop,
      () => {

        this.athlete.animationsManager.removeAnimationCallback(
          this.prepareAnimation,
          CallbackAnimationTypes.loop
        )
        this.startPrepareAnimationIdle()

      }
    )
    this.actualAnimation = this.prepareAnimation

    // pre niektore animacie upravime rychlost
    let speed = 0.3
    if ([PlayerAnimationsNames.prepare3, PlayerAnimationsNames.prepare4].includes(this.actualAnimation)) speed /= 0.7
    if (this.actualAnimation === PlayerAnimationsNames.prepare2) speed /= 0.9
    this.changeAnimationSpeed(speed)

    this.lastPrepareCrossfadeAnimationFrom = this.actualAnimation
    this.lastPrepareCrossfadeAnimationTo = this.prepareAnimation
    this.athlete.animationsManager.crossfadeTo(
      this.prepareAnimation,
      crossfadeTime,
      true,
      false
    )

    this.counterPrepareAnimations += 1

  }

  /**
   * zastavenie prepare animacii
   */
  public stopPrepareAnimations(): void {

    this.prepareAnimationIdleTween?.kill()

    this.athlete.animationsManager.stopCrossfade(
      this.lastPrepareCrossfadeAnimationFrom,
      this.lastPrepareCrossfadeAnimationTo
    )

    if (this.actualAnimation) {

      this.athlete.animationsManager.removeAnimationCallback(
        this.actualAnimation,
        CallbackAnimationTypes.loop
      )

    }

    if (this.prepareAnimation) {

      this.athlete.animationsManager.removeAnimationCallback(
        this.prepareAnimation,
        CallbackAnimationTypes.loop
      )

    }

    this.athlete.animationsManager.setWeight(this.prepareAnimation, 0)
    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.prepareIdle, 0)
    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.prepare1, 0)
    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.prepare2, 0)
    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.prepare3, 0)
    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.prepare4, 0)
    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.prepare5, 0)
    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.prepare6, 0)

  }

  /**
   * Riesenie veci pre game start stav
   * @returns True, ak ide o dany stav
   */
  private isGameStartState(): boolean {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.preStart) {

      if (!this.statesActive[PlayerStates.prepare]) {

        this.statesActive[PlayerStates.prepare] = true
        this.changeAnimationSpeed(0.3)
        this.startPrepareAnimation()
        this.athlete.bicycle.animationsManager.changeTo(BicycleAnimationsNames.prepare)

      }

      return true

    }

    return false

  }

  /**
   * Zistenie konkretnej animacie end happy
   * @returns Animacia
   */
  private getEndHappyAnimation(): PlayerAnimationsNames {

    const random = Math.ceil(Math.random() * 5)
    let animation = PlayerAnimationsNames.happy1
    if (random === 1) animation = PlayerAnimationsNames.happy2
    if (random === 2) animation = PlayerAnimationsNames.happy3
    if (random === 3) animation = PlayerAnimationsNames.happy4
    if (random === 4) animation = PlayerAnimationsNames.happy5

    gsap.to({}, {
      duration: 2,
      onComplete: () => {

        this.athlete.animationsManager.crossfadeTo(PlayerAnimationsNames.afterFinish, 0.5, true, false)

      }
    })

    return animation

  }

  /**
   * Riesenie veci pre tempo stav
   * @returns True, ak ide o dany stav
   */
  private isTempoState(): boolean {

    const isTempo = this.athlete.isState(PlayerStates.tempo)
    if (this.isStarting) {

      return isTempo

    }

    if (isTempo) {

      if (!this.statesActive[PlayerStates.tempo]) {

        this.statesActive[PlayerStates.tempo] = true
        this.statesActive[PlayerStates.sprint] = false
        if (this.athlete.playable) audioHelper.manageBreathing(false)

      }
      if (this.statesActive[PlayerStates.prepare]) {

        this.startTempo()
        return isTempo

      }
      this.tempoWeight += 1 / playerAnimationConfig.animationChangeFrames
      this.tempoWeight = Math.min(1, this.tempoWeight)

      this.athlete.animationsManager.manualCrossfadeTo(
        PlayerAnimationsNames.sprint,
        PlayerAnimationsNames.tempo,
        this.tempoWeight
      )
      this.actualAnimation = PlayerAnimationsNames.tempo


    }

    return isTempo

  }

  /**
   * Start tempo po prepare
   */
  public startTempo(): void {

    this.athlete.animationsManager.changeTo(PlayerAnimationsNames.tempo)
    this.athlete.bicycle.animationsManager.changeTo(BicycleAnimationsNames.rotate)
    this.statesActive[PlayerStates.prepare] = false
    this.tempoWeight = 1

  }

  /**
   * Riesenie veci pre sprint stav
   * @returns True, ak ide o dany stav
   */
  private isSprintState(): boolean {

    const isSprintState = this.athlete.isState(PlayerStates.sprint)

    if (isSprintState) {

      if (!this.statesActive[PlayerStates.sprint]) {

        this.statesActive[PlayerStates.sprint] = true
        this.statesActive[PlayerStates.tempo] = false
        if (this.athlete.playable) audioHelper.manageBreathing(true)

      }

      const { animationChangeFrames } = playerAnimationConfig
      this.tempoWeight -= 1 / animationChangeFrames
      this.tempoWeight = Math.max(0, this.tempoWeight)

      this.athlete.animationsManager.manualCrossfadeTo(
        PlayerAnimationsNames.tempo,
        PlayerAnimationsNames.sprint,
        1 - this.tempoWeight
      )
      this.actualAnimation = PlayerAnimationsNames.sprint

    }

    return isSprintState

  }

  /**
   * Riesenie veci pre end stav
   * @returns True, ak ide o dany stav
   */
  private isEndState(): boolean {

    const isEnd = this.athlete.isState(PlayerStates.end)

    if (!this.statesActive[PlayerStates.end] && isEnd) {

      this.statesActive[PlayerStates.end] = true

      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.tempo)
      this.athlete.animationsManager.setWeight(PlayerAnimationsNames.tempo, 1)
      this.athlete.animationsManager.setWeight(PlayerAnimationsNames.sprint, 0)
      const percentAnimation = this.athlete.animationsManager.getAnimationActualTime(PlayerAnimationsNames.tempo) /
        this.athlete.animationsManager.getDuration(PlayerAnimationsNames.tempo)
      this.athlete.bicycle.animationsManager.manualyUpdateTimeByPercent(BicycleAnimationsNames.rotate, percentAnimation)

      let animation = PlayerAnimationsNames.afterFinish
      if (this.athlete.emotionFinish === EmotionTypesFinish.winner) {

        animation = this.getEndHappyAnimation()

      } else if (this.athlete.playable) {

        this.counterDelaySad = 0
        return isEnd

      }

      this.athlete.animationsManager.crossfadeTo(animation, 0.5, true, false)
      this.actualAnimation = animation

    }

    return isEnd

  }

  /**
   * Oddialenie sad animacie
   */
  private delaySadAnimation(): void {

    if (this.counterDelaySad >= cameraConfig.finish.delayFrames) {

      this.counterDelaySad = -1
      const rand = THREE.MathUtils.randInt(1, 3)
      this.chooseSadAnimation(rand)

    }
    if (this.counterDelaySad >= 0) this.counterDelaySad += 1

  }

  /**
   * Vyber sad animacie
   * @param type - aky type animacie pouzijeme
   */
  private chooseSadAnimation(type: number): void {

    let animation = PlayerAnimationsNames.sad1

    if (type === 1) {

      this.athlete.animationsManager.crossfadeTo(animation, 0.5, true, false)
      this.actualAnimation = animation
      this.sadAnimationIdleTween = gsap.to({}, {
        duration: 2,
        onComplete: () => {

          this.athlete.animationsManager.crossfadeTo(PlayerAnimationsNames.afterFinish, 0.5, true, false)

        }
      })
      return

    }

    if (type === 2) animation = PlayerAnimationsNames.sad2
    if (type === 3) animation = PlayerAnimationsNames.sad3
    this.athlete.animationsManager.addAnimationCallback(
      animation,
      CallbackAnimationTypes.end,
      () => {

        this.athlete.animationsManager.removeAnimationCallback(
          animation,
          CallbackAnimationTypes.end
        )

        if (type === 2) this.chooseSadAnimation(1)
        if (type === 3) this.athlete.animationsManager.crossfadeTo(PlayerAnimationsNames.afterFinish, 0.5, true, false)

      }
    )


    this.athlete.animationsManager.changeTo(animation)
    this.actualAnimation = animation

  }

  /**
   * Riesenie veci pre lookBack stav
   * @returns True, ak ide o dany stav
   */
  private isLookBackState(): boolean {

    const isLookBack = this.athlete.isState(PlayerStates.lookBack)

    if (isLookBack) {

      if (
        this.statesActive[PlayerStates.lookBack] &&
        this.actualAnimation === PlayerAnimationsNames.lookBackR
      ) {

        this.manageWeightOfLookBack()
        if (this.lookBackWeight >= 2) {

          this.statesActive[PlayerStates.lookBack] = false
          this.athlete.setState(PlayerStates.tempo)
          this.lookBackWeight = 0

        }

        return isLookBack

      }
      this.statesActive[PlayerStates.lookBack] = true

      this.actualAnimation = PlayerAnimationsNames.lookBackR


    } else {

      if (this.lookBackWeight < 2) {

        this.manageWeightOfLookBack()

      }

    }

    return isLookBack

  }

  /**
   * Zmena vahy lookback
   */
  private manageWeightOfLookBack(): void {

    this.lookBackWeight += playerAnimationConfig.lookBackSpeed
    const actualWeight = this.lookBackWeight > 1 ? 2 - this.lookBackWeight : this.lookBackWeight

    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.lookBackR, actualWeight)
    this.athlete.animationsManager.setWeight(PlayerAnimationsNames.tempo, 1 - actualWeight)

  }

  /**
   * Samotna logika
   */
  private animationLogic(): void {

    this.isGameStartState()
    this.isTempoState()
    this.isSprintState()
    this.isLookBackState()
    this.isEndState()

  }

  /**
   * Upravuje rychlost animacii postupne na 1, ak treba
   */
  private manageAnimationSpeed(): void {

    if (this.athlete.isState(PlayerStates.prepare) || this.athlete.isState(PlayerStates.prepare2)) return
    const actualTempoSpeed = 0.4 + 0.65 * this.athlete.speedManager.getActualSpeed() / 20

    this.changeAnimationSpeed(actualTempoSpeed)

  }

  /**
   * Update metoda volana v move metode velocity manazera
   */
  public update(): void {

    this.manageAnimationSpeed()
    this.animationLogic()
    this.delaySadAnimation()

  }

  /**
   * Zresetovanie veci
   */
  public reset(): void {

    this.actualAnimation = undefined
    this.isEndEmotionSet = false
    this.statesActive = {
      [PlayerStates.prepare]: false,
      [PlayerStates.starting]: false,
      [PlayerStates.tempo]: false,
      [PlayerStates.sprint]: false,
      [PlayerStates.lookBack]: false,
      [PlayerStates.end]: false
    }
    this.counterPrepareAnimations = 0
    this.counterDelaySad = -1

  }

}
