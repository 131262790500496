import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface LapPositionState {
  lap: number,
  showLap: boolean,
  position: string,
  showPosition: boolean
}

const initialState = () => ({
  lap: 0,
  showLap: false,
  position: '',
  showPosition: false
})

const lapPositionState: Module<LapPositionState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getLapPositionState: (moduleState: LapPositionState) => moduleState,
  },

  mutations: {
    RESET: (moduleState: LapPositionState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: LapPositionState, newState: LapPositionState) => {

      if (moduleState.lap !== undefined) moduleState.lap = newState.lap
      if (moduleState.position !== undefined) moduleState.position = newState.position
      if (moduleState.showLap !== undefined) moduleState.showLap = newState.showLap
      if (moduleState.showPosition !== undefined) moduleState.showPosition = newState.showPosition

    },
    SET_LAP: (moduleState: LapPositionState, lap: number) => {

      moduleState.lap = lap

    },
    SET_POSITION: (moduleState: LapPositionState, position: string) => {

      moduleState.position = position

    },
    SET_SHOW_LAP: (moduleState: LapPositionState, show: boolean) => {

      moduleState.showLap = show

    },
    SET_SHOW_POSITION: (moduleState: LapPositionState, show: boolean) => {

      moduleState.showPosition = show

    },
  }
}

export default lapPositionState
