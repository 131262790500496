import { THREE } from '@powerplay/core-minigames'

/**
 * Konfig pre lunge
 */
export const lungeConfig = {

  /** Pocet metrov pred cielom, kedy je dostupny lunge */
  distanceBeforeFinish: 10,

  /** Min preklon v metroch - zabezpecuje offset medzi prednym kolesom a pivot pointom */
  lungeOffsetMin: 0.9,

  /** coef na slowmo */
  speedLockCoefCameraChange: 0.1,

  /** coef na slowmo */
  speedLockCoefFinishLine: 0.8,

  /** offset kamery pri speedLocku */
  cameraOffset: new THREE.Vector3(-1, 2, 0),

  /** cas v s za aky sa presunie kamera */
  cameraTransitionDuration: 2,

  /** ci je povoleny speedLock a side kamera */
  speedLockEnabled: true

}
