import {
  fpsManager,
  playersManager
} from '@powerplay/core-minigames'
import { velocityConfig } from '../config'
import type { Athlete } from '../entities/athlete'
import { player } from '../entities/athlete/player'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'

/**
 * Manager rychlosti hraca
 */
export class SpeedManager {

  /** ci je speedManager aktivny */
  private active = false

  /** momentalna rychlost v m/s */
  private actualSpeed = 0

  /** min speed */
  public minSpeed = 0

  /** cruiseSpeedConst */
  public cruiseSpeedConst = 0

  /** sprintSpeedConst */
  public sprintSpeedConst = 0

  /** pocitanie framov po slipStreame */
  private slipStreamCooldown = velocityConfig.slipstreamBonusCooldown + 1

  /**
   * Nastavenie minSpeed podla atributu
   * @param attribute - atribut
   * @param uuid - uuid
   * @param playable - ci je to hrac
   */
  public setMinSpeed(attribute: number, uuid: string, playable: boolean): void {

    if (attribute < 100) {

      this.minSpeed = 10.4 + (attribute * 0.002)
      this.cruiseSpeedConst = 4.2 + (attribute * 0.001)
      this.sprintSpeedConst = 3.2 + (attribute * 0.001)

    } else if (attribute < 1000) {

      this.minSpeed = 10.6 + ((attribute - 100) * 0.001)
      this.cruiseSpeedConst = 4.3 + ((attribute - 100) * 0.0005)
      this.sprintSpeedConst = 3.3 + ((attribute - 100) * 0.0005)

    } else {

      this.minSpeed = 11.5 + ((attribute - 1000) * 0.0005)
      this.cruiseSpeedConst = 4.75 + ((attribute - 1000) * 0.00025)
      this.sprintSpeedConst = 3.75 + ((attribute - 1000) * 0.00025)

    }
    if (!playable) {

      this.minSpeed = player.speedManager.minSpeed + (attribute - playersManager.getPlayer().attribute.total) * 0.005
      this.minSpeed = Math.max(10, this.minSpeed)
      this.minSpeed = Math.min(12, this.minSpeed)

    }

    console.log(`${uuid} 
minSpeed: ${this.minSpeed}
cruiseSpeedConst: ${this.cruiseSpeedConst}
sprintSpeedConst: ${this.sprintSpeedConst}`)

  }

  /**
   * Vracia rychlost v metroch za sekundu
   * @returns actualSpeed m/s
   */
  public getActualSpeed(): number {

    return this.actualSpeed

  }

  /**
   * Setter
   * @param speed - nova rychlost
   */
  public setActualSpeed(speed: number): void {

    this.actualSpeed = speed

  }

  /**
   * Vracia rychlost v metroch za frame
   * @returns actualSpeedPerFrame m/f
   */
  public getActualSpeedPerFrame(): number {

    return this.actualSpeed / fpsManager.fpsLimit

  }

  /**
   * Setter active
   * @param active - active
   */
  public setActive(active: boolean): void {

    this.active = active

  }

  /**
   * Vratenie, ci je aktivna rychlost
   * @returns True, ak je aktivna
   */
  public isActive(): boolean {

    return this.active

  }

  /**
   * Ziskanie localMaxSpeed
   * @param minIdealValue - Minimalna hodnota idealu
   * @param globalMaxSpeed - globalMaxSpeed
   * @returns localMaxSpeed
   */
  public getLocalMaxSpeed(minIdealValue: number, globalMaxSpeed: number): number {

    return (0.5 + ((minIdealValue - 110) / 160)) * globalMaxSpeed

  }

  /**
   *
   * @param speedbarValue - aktualna hodnota speed baru
   */
  private setSpeed(speedbarValue: number,): void {

    const {
      slipstreamBonus,
      forwardForce,
      backwardForce,
      slipstreamBonusCooldown
    } = velocityConfig
    let maxSpeed = 0

    if (speedbarValue <= 80) {

      maxSpeed = this.minSpeed + speedbarValue / 80 * this.cruiseSpeedConst

    } else {

      maxSpeed = this.minSpeed + this.cruiseSpeedConst + ((speedbarValue - 80) / 20) * this.sprintSpeedConst

    }

    if (this.slipStreamCooldown <= slipstreamBonusCooldown) {

      maxSpeed += slipstreamBonus

    }
    if (this.actualSpeed <= maxSpeed) {

      this.actualSpeed += forwardForce

    } else {

      this.actualSpeed -= backwardForce

    }

    this.actualSpeed = Math.min(maxSpeed, this.actualSpeed)
    this.actualSpeed = Math.max(this.minSpeed, this.actualSpeed)

  }

  /**
   * Aktualizovanie veci kazdy frame
   * @param speedbarValue - aktualna hodnota speed baru
   * @param athlete - athlet
   */
  public update(speedbarValue: number, athlete: Athlete): void {

    if (!disciplinePhasesManager.phaseRunning.runStarted) return

    this.setSpeed(speedbarValue)
    if (athlete.isInSlipStream) {

      this.slipStreamCooldown = 0

    } else {

      this.slipStreamCooldown += 1

    }
    if (athlete.inSafeZoneOfAthlete !== undefined) {

      this.manageSpeedbarMax(athlete)
      if (athlete.inSafeZoneOfAthlete.speedManager.getActualSpeed() <= athlete.speedManager.getActualSpeed()) {

        athlete.speedManager.setActualSpeed(athlete.inSafeZoneOfAthlete.speedManager.getActualSpeed())
        if (athlete.playable && !athlete.isEnd) {

          athlete.showBlockingEffect(undefined)

        }
        return

      }

    } else {

      athlete.speedBarManager.speedbarTempMax = athlete.speedBarManager.speedBarMax

    }
    this.setSpeed(speedbarValue)

  }

  /**
   * Vypocet a uprava speedbarMax
   * @param athlete - athlet
   */
  private manageSpeedbarMax(athlete: Athlete): void {

    let speedbarTempMax = athlete.speedBarManager.getSpeedBarMax()
    const { slipstreamBonus } = velocityConfig
    const speed = athlete.inSafeZoneOfAthlete?.speedManager.getActualSpeed() || 0
    if (speed > (this.minSpeed + this.cruiseSpeedConst + slipstreamBonus)) {

      speedbarTempMax = 80 + (speed - this.minSpeed - this.cruiseSpeedConst - slipstreamBonus) /
        this.sprintSpeedConst * 20
      speedbarTempMax = Math.min(speedbarTempMax, athlete.speedBarManager.speedBarMax)

    } else {

      speedbarTempMax = (speed - this.minSpeed - slipstreamBonus) / this.cruiseSpeedConst * 80

    }
    speedbarTempMax = Math.max(0, speedbarTempMax)
    athlete.speedBarManager.speedbarTempMax = speedbarTempMax

  }

  /**
   * reset
   */
  public reset(): void {

    this.actualSpeed = 0
    this.active = false

  }

}
