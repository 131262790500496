import {
  game,
  THREE
} from '@powerplay/core-minigames'
import { player } from '../athlete/player'

/**
 * Trieda pre prostredie
 */
export class WorldEnv {

  /** Priehladna stena v cieli */
  private finishWall!: THREE.Mesh

  /**
   * Vytvorenie prostredia
   */
  public create(): void {

    console.log('VYTVARAM ENV....')

    game.getMesh('TrackCycling_Mesh001_3').renderOrder = 2
    this.finishWall = game.getMesh('finish_TrackCycling_Finish')
    this.setVisibilityFinishWall(false)

    console.log('ENV vytvoreny....')

  }

  /**
   * Nastavenie viditelnosti steny v cieli
   * @param visibility - Viditelnost
   */
  public setVisibilityFinishWall(visibility: boolean): void {

    this.finishWall.visible = visibility

  }

  /**
   * Nastavenie tienov
   */
  public setUpShadows(): void {

    game.shadowsManager.setObjectToFollowWithLight(player.athleteObject)
    this.setMeshAsShadowPlane('TrackCycling_Mesh001_12')
    this.setMeshAsShadowPlane('TrackCycling_Mesh001_7')

  }

  /**
   * Nastavenie meshu ako shadow plane
   * @param meshName - nazov meshu
   */
  public setMeshAsShadowPlane(meshName: string): void {

    const mesh = game.getMesh(meshName).clone()
    mesh.name += 'cloned'
    game.scene.add(mesh)
    game.shadowsManager.setTerrainShadowPlane(mesh)

  }

}

export const worldEnv = new WorldEnv()
