/** Konfig pre start */
export const startConfig = {

  /** Pocet sekund, kolko bude maximalna odchylka od zaciatku stavu set, aby nesli vsetci naraz */
  maxRandomDelaySet: 0.4,

  /** Max pocet frameov pre hlasku */
  startMessageFrames: {
    perfect: 5,
    excellent: 8,
    good: 10
  },

  /** Ci bude zobrazeny debug startovaci bar */
  debugStartBar: false,

  /** poriadie na zaciatku */
  playersStartOrder: {
    active: false,
    data: {
      'uuidPlayer': 5,
      'uuidOpponent2': 1,
      'uuidOpponent3': 2,
      'uuidOpponent4': 3,
      'uuidOpponent5': 4,
      'uuidOpponent6': 0,
    }
  }

}