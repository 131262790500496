<template>
  <mobile-button
    :type="'keirin'"
    :is-scaled="false"
    :disabled="disabled"
    :glow="glow"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'
import { inputsManager } from '@powerplay/core-minigames'

import { defineComponent } from 'vue'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

export default defineComponent({
  components: {
    MobileButton
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    glow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getActionDisabled: 'ActionButtonState/getActionDisabled'
    })
  },
  methods: {
    performAction() {

      if (this.getActionDisabled) return

      disciplinePhasesManager.phaseRunning.handleInputs()

    },
    endAction() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})

</script>

<style>

</style>
