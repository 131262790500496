/** Konfig pre start */
export const sprintConfig = {

  /** pocet framov medzi kazdou aktualizaciou sprint baru */
  sprintBarFrameRate: 6,

  /** rate doplnania sprintBaru */
  sprintBarFillRate: 1,

  /** koeficient ked je hrac v zavetri */
  slipStreamCoef: 1.5,

  /** rate ubudania sprintBaru */
  sprintBarDecreaseRates: [1, 0.8, 0.6, 0.4],

  /** ako dlho musi byt sprint na 0 aby sa nieco stalo */
  sprintOnZeroFrameRate: 10,

  /** o kolko sa ma znizovat maxSpeedBar */
  maxSpeedBarDecrease: 1,

  /** Hodnota sprint baru na zaciatku */
  sprintBarDefaultValue: 0

}