import {
  minigameConfig,
  PlayerRace,
  PlayerSex,
  type PlayerInfo
} from '@powerplay/core-minigames'

/**
 * Fake superi pre tutorial, trening a dennu ligu
 */
export const fakeOpponents: PlayerInfo[] = [
  {
    uuid: 'uuid1',
    name: 'Noah',
    country: 'usa',
    countryString: 'usa',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid2',
    name: 'Evelyn',
    country: 'jam',
    countryString: 'jam',
    sex: PlayerSex.female,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid3',
    name: 'Donovan',
    country: 'can',
    countryString: 'can',
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid4',
    name: 'Victoria',
    country: 'gbr',
    countryString: 'gbr',
    sex: PlayerSex.female,
    race: PlayerRace.mulatto,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid5',
    name: 'Richard',
    country: 'tto',
    countryString: 'tto',
    sex: PlayerSex.male,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
]
