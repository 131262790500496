import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const audioSprites: AudioObject[] = [
  {
    files: [
      AudioNames.spriteAudience
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    version: 2,
    category: AudioCategories.sprite,
    sprite: {
      '100m_hyped_overlay': [
        0,
        16056.077097505668
      ],
      'audience-hyped-overlay-5-finish': [
        18000,
        15168.00453514739
      ],
      'audience-noise': [
        35000,
        14796.893424036283
      ]
    }
  },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'VO_cycling_finish_rank1_3_var01': [
        0,
        5276.734693877551
      ],
      'VO_cycling_finish_rank1_3_var012': [
        7000,
        7288.163265306122
      ],
      'VO_cycling_finish_rank1_var01': [
        16000,
        5851.428571428571
      ],
      'VO_cycling_finish_rank1_var012': [
        23000,
        5250.612244897959
      ],
      'VO_cycling_finish_rank2_3': [
        30000,
        7810.61224489796
      ],
      'VO_cycling_finish_rank4': [
        39000,
        6426.12244897959
      ],
      'VO_cycling_lap1_rank1': [
        47000,
        3631.0204081632664
      ],
      'VO_cycling_lap1_rank2_3': [
        52000,
        5825.306122448978
      ],
      'VO_cycling_lap1_rank4': [
        59000,
        5877.551020408163
      ],
      'VO_cycling_lap2_rank1': [
        66000,
        4545.3061224489775
      ],
      'VO_cycling_lap2_rank2_3': [
        72000,
        6400.0000000000055
      ],
      'VO_cycling_lap2_rank4': [
        80000,
        6347.755102040821
      ]
    }
  }
]