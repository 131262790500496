import store from '@/store'
import {
  velocityConfig,
  gameConfig
} from '../../config'
import type { Athlete } from './Athlete'
import { BlueBoxTextType } from '@/app/types'

/**
 * Trieda pre spravu rychlosti hraca
 */
export class AthleteSpeedBarManager {

  /** Ci su inputy bloknute alebo nie */
  public inputsBlocked = true

  /** Sila ktorou upravujeme rychlost */
  private speedPower = velocityConfig.speedBar.minValue

  /** Minimalna idealna hodnota */
  private sprintThreshold = velocityConfig.speedBar.sprintThresholdValue.max

  /** Aktualna hodnota frameov pre freeze po kliku */
  private freezeFrames = 0

  /** Posledna nastavena hodnota pre freeze po kliku */
  private lastFreezeFrames = 0

  /** Pocitadlo frameov pre zmenu hodnoty speedbaru */
  private frameCounterEnd = 0

  /** Ci sa ma updatovat pocet frameov po poslednom kliku */
  private updateFramesAfterLastInputActive = false

  /** Pocet framov po poslednom kliku */
  private framesAfterLastInput = 0

  /** kolko krat stlacil run */
  private numberOfRunInputs = 0

  /** speedBar max */
  public speedBarMax = 100

  /** speedBarTemp max */
  public speedbarTempMax = 100

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Vratenie hodnoty speed bar
   * @returns hodnota speed baru
   */
  public getSpeedPower(): number {

    return this.speedPower

  }

  /**
   * Getter
   * @returns speedBarMax
   */
  public getSpeedBarMax(): number {

    return this.speedBarMax < this.speedbarTempMax ? this.speedBarMax : this.speedbarTempMax

  }

  /**
   * Vratenie hodnoty speed bar
   * @returns hodnota speed baru
   */
  public setSpeedPower(speedPower: number): void {

    this.speedPower = speedPower
    this.speedPower = Math.min(this.speedPower, this.getSpeedBarMax())
    this.speedPower = Math.max(this.speedPower, 0)

  }

  /**
   * Vratenie minimalnej hodnoty idealu
   * @returns hodnota
   */
  public getSprintThreshold(): number {

    return this.sprintThreshold

  }

  /**
   * Zistenie, v akom percente sprint casti si
   * @returns Percento 0-1
   */
  public getPercentFromSprintPart(): number {

    return Math.max(
      (this.speedPower - this.sprintThreshold) / (this.speedBarMax - this.sprintThreshold),
      0
    )

  }

  /**
   * Nastavenie freeze po kliku
   */
  private setFreezeAfterInput(): void {

    const { freeze } = velocityConfig.speedBar

    let index = 0

    if (this.speedPower < freeze[0].limitMax) {

      index = 0

    } else if (this.speedPower < freeze[1].limitMax) {

      index = 1

    }

    this.lastFreezeFrames = this.freezeFrames
    this.freezeFrames = freeze[index].frames

  }

  /**
   * Kontrola inputov a spravenie veci, co sa maju pri nich vykonat
   */
  public handleInputs(): void {

    if (!this.athlete.speedManager.isActive() || this.inputsBlocked || !this.athlete.playable) return

    if (!this.updateFramesAfterLastInputActive) {

      console.log('Bol prvy klik')

    } else {

      // console.log(`Posledny klik bol pred ${this.framesAfterLastInput} frameami`)
      this.framesAfterLastInput = 0

    }

    this.updateFramesAfterLastInputActive = true

    this.numberOfRunInputs += 1
    if (this.numberOfRunInputs === 3) {

      store.commit('UiState/SET_BLUE_BOX_TEXT_TYPE', BlueBoxTextType.hidden)

    }
    this.addPower()
    this.setFreezeAfterInput()

  }

  /**
   * Zvysenie hodnoty baru
   */
  private addPower(): void {

    const { stepAdd, maxValue } = velocityConfig.speedBar
    let index = 3

    if (this.speedPower < stepAdd[0].limitMax) {

      index = 0

    } else if (this.speedPower < stepAdd[1].limitMax) {

      index = 1

    } else if (this.speedPower < stepAdd[2].limitMax) {

      index = 2

    }

    const min = stepAdd[index].minAdd
    const max = stepAdd[index].maxAdd
    const percentFreezeLeft = this.lastFreezeFrames === 0 ? -1 : this.freezeFrames / this.lastFreezeFrames

    if (percentFreezeLeft === -1) {

      this.speedPower += stepAdd[index].baseAdd

    } else {

      this.speedPower += Math.round(min + (percentFreezeLeft * (max - min)))

    }

    this.speedPower = Math.min(this.speedPower, maxValue, this.getSpeedBarMax())

  }

  /**
   * Znizenie hodnoty baru
   */
  private removePower(): void {

    if (this.athlete.playable && gameConfig.autoMove.isEnabled) return
    // ak je freeze, tak neklesa bar, takisto to neplati pre supera, ten ma napevno hodnoty
    if (this.freezeFrames > 0 || !this.athlete.playable || this.athlete.isEnd) return

    const { stepRemove, minValueAfterStart } = velocityConfig.speedBar

    let index = 0

    if (this.speedPower < stepRemove[0].limitMax) {

      index = 0

    } else if (this.speedPower < stepRemove[1].limitMax) {

      index = 1

    }

    this.speedPower -= stepRemove[index].value
    if (this.speedPower < minValueAfterStart) this.speedPower = minValueAfterStart

  }

  /**
   * Sprava freezu po kliku
   */
  private manageFreeze(): void {

    if (this.freezeFrames > 0) this.freezeFrames -= 1

  }

  /**
   * Sprava min ideal hodnoty
   */
  private manageSprintThreshold(): void {

    const { sprintThresholdValue } = velocityConfig.speedBar

    this.sprintThreshold = this.speedBarMax - 20

    this.sprintThreshold = Math.max(sprintThresholdValue.min, this.sprintThreshold)
    this.sprintThreshold = Math.min(sprintThresholdValue.max, this.sprintThreshold)

  }

  /**
   * Aktualizovanie rychlosti
   */
  public update(): void {

    if (this.athlete.isEnd) {

      this.frameCounterEnd += 1

    }
    if (
      this.athlete.isEnd &&
      (
        this.frameCounterEnd >=
          velocityConfig.finishSlowDownFramesDelayDelta * 6 -
          velocityConfig.finishSlowDownFramesDelayDelta * this.athlete.positionOnFinish
      ) &&
      (this.frameCounterEnd % 10 === 0)
    ) {

      this.setSpeedPower(this.speedPower - (2 + this.athlete.positionOnFinish))

    }
    if (this.updateFramesAfterLastInputActive) {

      this.framesAfterLastInput += 1

    }

    // this.setSpeedFromArray()
    this.removePower()
    this.manageFreeze()

    this.manageSprintThreshold()
    this.athlete.sprintBarManager.update(this.speedPower, this.sprintThreshold)

    // UI update - iba pre hraca
    if (!this.athlete.playable) return
    const speedColor = this.speedPower <= this.sprintThreshold ? 'purple' : 'pink'

    store.commit('MainState/SET_STATE', {
      speed: this.athlete.speedManager.getActualSpeed()
    })
    store.commit('SpeedBarState/SET_STATE', {
      speedPower: this.speedPower,
      mark: this.sprintThreshold,
      speedColor: speedColor,
      speedBarMax: this.speedBarMax,
      sprintBarValue: this.athlete.sprintBarManager.sprintBarValue,
      isSlipStream: this.athlete.isInSlipStream,
      sprintBarFilling: this.athlete.sprintBarManager.sprintBarFilling,
      speed: this.athlete.speedManager.getActualSpeed()
    })

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.speedPower = velocityConfig.speedBar.minValue
    this.sprintThreshold = 0
    this.freezeFrames = 0
    this.inputsBlocked = true
    this.frameCounterEnd = 0

    this.lastFreezeFrames = 0
    this.updateFramesAfterLastInputActive = false
    this.framesAfterLastInput = 0
    this.numberOfRunInputs = 0
    this.speedBarMax = 100
    this.speedbarTempMax = 100

  }

}
