import {
  game,
  THREE
} from '@powerplay/core-minigames'
import type { Athlete } from '../athlete'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  gameConfig,
  helperManConfig
} from '@/app/config'
import { DisciplinePhases } from '@/app/types'

/**
 * Trieda pre pomocnika
 */
export class HelperMan {

  /** Mesh pomocnika */
  private mesh!: THREE.Mesh

  /** Typ animacie */
  private animationType = 0

  /** Smer animacie */
  private animationDirection = 0

  /** Step animacie */
  private animationStep = 0

  /** Pocet frameov na freeze */
  private freezeFrames = 0

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Vytvorenie luku
   */
  public create(): void {

    this.mesh = game.getMesh('helperMan_pomocnik').clone()
    this.mesh.name += `_${this.athlete.uuid}`
    this.athlete.athleteObject.add(this.mesh)

    const indexOrder = this.athlete.startingOrder
    const materialName = disciplinePhasesManager.HELPER_MAN_COLORS[gameConfig.helperManOrder[indexOrder]]
    this.mesh.material = game.materialsToUse.basic.get(materialName) as THREE.MeshBasicMaterial

    this.animationType = indexOrder % 2 === 0 ? 0 : 1
    this.animationStep = THREE.MathUtils.randFloat(helperManConfig.animationStep.min, helperManConfig.animationStep.max)

    if (this.mesh.morphTargetInfluences) {

      const random = Math.random()
      this.mesh.morphTargetInfluences[this.animationType] = random
      this.animationDirection = random < 0.5 ? 1 : -1

    }

  }

  /**
   * Nastavenie viditelnosti
   * @param visibility - Viditelnost
   */
  public setVisibility(visibility: boolean): void {

    this.mesh.visible = visibility

  }

  /**
   * Aktualizovanie pomocnika
   * @param delta - Delta
   */
  public update(delta: number): void {

    if (
      !this.mesh.morphTargetInfluences ||
      disciplinePhasesManager.phaseStart.getCameraInPostIntroState() ||
      !disciplinePhasesManager.oneOfPhaseIsActual([DisciplinePhases.start])
    ) return

    if (this.freezeFrames > 0) {

      this.freezeFrames -= 1
      return

    }


    this.mesh.morphTargetInfluences[this.animationType] += (this.animationStep * this.animationDirection * delta)

    if (this.animationDirection === 1 && this.mesh.morphTargetInfluences[this.animationType] >= 1) {

      this.mesh.morphTargetInfluences[this.animationType] = 1
      this.animationDirection *= -1
      this.freezeFrames = THREE.MathUtils.randInt(helperManConfig.freezeFrames.min, helperManConfig.freezeFrames.max)

    }

    if (this.animationDirection === -1 && this.mesh.morphTargetInfluences[this.animationType] <= 0) {

      this.mesh.morphTargetInfluences[this.animationType] = 0

      // dame druhu animaciu
      if (Math.round(Math.random()) === 0) {

        this.animationType = 1 - this.animationType

      }

      this.animationDirection *= -1
      this.freezeFrames = THREE.MathUtils.randInt(helperManConfig.freezeFrames.min, helperManConfig.freezeFrames.max)

    }

  }

}

