import {
  THREE,
  game,
  cameraManager,
  errorManager,
  playersManager,
  CameraStates,
  modes,
  gsap
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig,
  pathsConfig,
  trainingConfig
} from '../../../config'
import {
  BlueBoxTextType,
  CustomGameEvents,
  DisciplinePhases,
  ModelsNames,
  Sides,
  Tasks,
  TutorialEventType
} from '../../../types'
import { Athlete } from '..'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import store from '@/store'
import { trainingTasks } from '@/app/modes/training'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { cameraShake } from './CameraShake'
import { endManager } from '@/app/EndManager'
import { worldEnv } from '../../env/WorldEnv'

/**
 * Trieda pre hraca
 */
export class Player extends Athlete {

  /** tween ovlada ako dlho ma byt zobrazney blockingEffect */
  private blockingEffectTween?: gsap.core.Tween

  /** stara player position pred resetom */
  private oldPlayerPos: {[key in CameraStates]: THREE.Vector3 | undefined} = {
    [CameraStates.intro]: undefined,
    [CameraStates.disciplineIntro]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.disciplineOutro]: undefined,
    [CameraStates.intro2]: undefined,
    [CameraStates.static]: undefined,
    [CameraStates.table]: undefined,
  }

  /** ci sa ma dalsi frame nastavit pozicia hraca v cieli */
  private nextFrameSetPosition = false

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const meshAthleteName = modelsConfig[ModelsNames.athlete]?.mainMeshNames?.[0]
    if (!meshAthleteName) {

      throw new Error(errorManager.showBox('Mesh name for athlete was not defined'))

    }

    return game.getObject3D(meshAthleteName)

  }

  /**
   * Vytvorenie lyziara
   * @param trackIndex - Index drahy
   * @param startingOrder - pozicia pri starte
   * @param animationPrepareChainIndex - Index pre retaz kombinacie prepare animacii
   */
  public create(trackIndex: number, startingOrder: number, animationPrepareChainIndex: number): void {

    console.log('vytvaram hraca...')

    this.uuid = playersManager.getPlayer().uuid

    super.create(trackIndex, startingOrder, animationPrepareChainIndex, 'Player', ModelsNames.athlete)

  }

  /**
   * Aktualizovanie kamera konfigu na zaciatku este
   * @param cameraState - Kamerovy stav
   */
  public updateCameraConfigOnStart(cameraState: CameraStates): void {

    const playerPosition = player.getPosition().clone()

    const cameraData = cameraManager.getTweenSettings()[cameraState]
    if (!cameraData) return

    const cameraDataFromState = (Array.isArray(cameraData)) ? cameraData[0] : cameraData

    // po resete chceme resetnut nastavenia na povodne
    if (this.oldPlayerPos[cameraState]) {

      cameraDataFromState.startPosition.sub(this.oldPlayerPos[cameraState] as THREE.Vector3)
      cameraDataFromState.endPosition.sub(this.oldPlayerPos[cameraState] as THREE.Vector3)

    }
    this.oldPlayerPos[cameraState] = playerPosition.clone()

    cameraDataFromState.startPosition.add(playerPosition)
    cameraDataFromState.endPosition.add(playerPosition)

  }

  /**
   * Konecna akcia pre hraca
   */
  public finishAction(): void {

    // najskor musime ukoncit moznost pohybovych animacii
    this.activeUpdatingMovementAnimations = false

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp
    )

  }

  /**
   * nastavime camera settings podla game configu
   *
   * @param lerpSize - volitelny iny lerp ako v game configu
   */
  public setGameCameraSettings(lerpSize = gameConfig.cameraConfig.changeLerp): void {

    this.changeCameraSettings(
      gameConfig.cameraConfig.idealOffset,
      gameConfig.cameraConfig.idealLookAt,
      gameConfig.cameraConfig.coefSize,
      lerpSize
    )

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    super.finishReached(actualPosition, lastPosition, finishPosition)
    tutorialFlow.eventActionTrigger(TutorialEventType.finish)
    store.commit('TextMessageState/SET_SHOW_TOGO', false)
    store.commit('LapPositionState/SET_SHOW_LAP', false)
    store.commit('ArrowDistancesState/SET_SHOW', false)
    store.commit('UiState/SET_BLUE_BOX_TEXT_TYPE', BlueBoxTextType.hidden)
    this.nextFrameSetPosition = true
    worldEnv.setVisibilityFinishWall(false)

    playersManager.setPlayerResults(this.finalTime)
    endManager.finishSpeed = this.speedManager.getActualSpeed()

    disciplinePhasesManager.phaseRunning.finishPhase()

    // mimo dennej ligy davame vsetkym superom, co este nepresli cielom po hracovi DNF, aby sa dobre vyhodnocovalo
    if (!modes.isDailyLeague()) window.dispatchEvent(new CustomEvent(CustomGameEvents.playerFinished))

    this.hideBlockingEffect(undefined)

    audioHelper.stopBreathing()
    audioHelper.stopHeartbeat()

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param actualPhase - Aktualna faza
   */
  public updateBeforePhysics(actualPhase: DisciplinePhases): void {

    super.updateBeforePhysics(actualPhase)
    if (actualPhase >= DisciplinePhases.running && !this.isEnd && disciplinePhasesManager.phaseRunning.runStarted) {

      trainingTasks.countTaskValue(Tasks.maxSpeed, this.speedManager.getActualSpeed())
      tutorialFlow.checkRequiredSpeed(this.speedManager.getActualSpeed() * 3.6)

    }

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   */
  public updateAfterPhysics(): void {

    super.updateAfterPhysics()

    if (this.nextFrameSetPosition) {

      this.nextFrameSetPosition = false
      playersManager.setStandings(3)
      this.positionOnFinish = playersManager.getPlayerActualPosition(this.uuid) - 1
      console.log('STDNGS: ', this.positionOnFinish, playersManager.getStandings())
      store.commit('LapPositionState/SET_POSITION', gameConfig.positionDictionary[this.positionOnFinish])

      trainingTasks.saveTaskValue(
        Tasks.finishPosition,
        trainingConfig.finishPositionQuality[this.positionOnFinish] || 0
      )

    }

  }

  /**
   * Zobrazenie blocking grafiky
   * @param side - strana narazu
   */
  public showBlockingEffect(side: Sides | undefined): void {

    if (this.speedBarManager.inputsBlocked) return

    store.commit('BlockingState/SET_STATE', { side,
      isVisible: true })
    this.blockingEffectTween?.kill()
    this.blockingEffectTween = gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        this.hideBlockingEffect(side)
        cameraShake.resetFreezedType()

      }
    })

    // zatrasenie
    cameraShake.shake(side)

    if (!modes.isTutorial()) return
    if (
      side !== undefined &&
            this.worldEnvLinesManager.pathIndex > 0 &&
            this.worldEnvLinesManager.pathIndex < pathsConfig.tracks - 1
    ) {

      console.log(side, this.worldEnvLinesManager.pathIndex, pathsConfig.tracks + 1)
      // tutorialFlow.eventActionTrigger(TutorialEventType.pathBlocked)

    }

  }
  /**
   * Schovanie blocking grafiky
   */
  private hideBlockingEffect(side: Sides | undefined): void {

    store.commit('BlockingState/SET_STATE', { side,
      isVisible: false })

  }

  /**
   * reset atleta
   */
  public reset(): void {

    super.reset()

    this.nextFrameSetPosition = false

  }

}

export const player = new Player('')
