/** Konfig pre nastavenie rychlosti hraca */
export const velocityConfig = {

  /** Speed bar veci */
  speedBar: {

    /** minimalna hodnota speed baru */
    minValue: 0,

    /** minimalna hodnota speed baru po starte */
    minValueAfterStart: 0,

    /** maximalna hodnota speed baru */
    maxValue: 100,

    /** Minimalna idealna hodnota */
    sprintThresholdValue: {
      min: 0,
      max: 80
    },

    /** hodnota pri zvysovani hodnoty speed baru */
    stepAdd: [
      { limitMax: 30,
        baseAdd: 15,
        minAdd: 15,
        maxAdd: 20 },
      { limitMax: 60,
        baseAdd: 10,
        minAdd: 10,
        maxAdd: 15 },
      { limitMax: 80,
        baseAdd: 8,
        minAdd: 8,
        maxAdd: 10 },
      { limitMax: 999,
        baseAdd: 6,
        minAdd: 6,
        maxAdd: 8 }
    ],

    /** hodnota pri znizovani hodnoty speed baru */
    stepRemove: [
      { limitMax: 80,
        value: 1 },
      { limitMax: 100,
        value: 2 }
    ],

    /** Freeznutie po kliku */
    freeze: [
      { limitMax: 80,
        frames: 8 },
      { limitMax: 100,
        frames: 6 }
    ],

    /** minimalna hodnota speedBarMax */
    speedbarMaxMinimum: 60

  },

  /** zona zavetria v metroch */
  slipStreamZone: 10,

  /** safe zona do ktorej sa iny hrac nemoze dostat */
  minDistanceSlipstream: 1.8,

  /** safe zona do ktorej sa iny hrac nemoze dostat za cielom */
  minDistanceSlipstreamEnd: 3,

  /** safe zona v poslednom kole */
  minDistLastLap: 3,

  /** bonus v zavetri */
  slipstreamBonus: 0.8,

  /** forward foce */
  forwardForce: 0.03,

  /** backward force */
  backwardForce: 0.1,

  /** slipstreamBonusCooldown framov */
  slipstreamBonusCooldown: 90,

  /** pocet framov medzi spomalovanim hracov */
  finishSlowDownFramesDelayDelta: 5

}
