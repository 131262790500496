import {
  HairColorTypes,
  THREE
} from '@powerplay/core-minigames'
import { LeaderColors } from '../types'

/** Konfig pre nastavenie fyziky a aj celej hry */
export const gameConfig = {

  /** Pocet pokusov */
  numberOfAttempts: 1,

  /** pocet kol */
  numberOfLaps: 3,

  /** nastavenie kamery aplikovane po starte. */
  cameraConfig: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(0, 2, -3),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 0, 2),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1,

    // fov
    fov: 50,

  },

  /**
   * starting position
   * POZN.: cannon ve3 to je kvoli tomu, aby sedel vypocet distanceTo aj v cannon svete
   */
  start: {
    position: new THREE.Vector3(0, 0.22, -24.820),
    yOffset: 0.18,
    zOffset: 0.8
  },

  /**
   * defaultna rychlost animacii
   */
  defaultAnimationSpeed: 1,

  /**
   * Ci mam aktivny skip do ciela a na akej pozicii to zacina
   */
  skipToFinish: {

    active: false,
    percent: 0.85

  },

  /** fix pozicie y pre hraca, aby nebol vo vzduchu */
  yPlayerCorrection: 0,

  /** Lerp pre quaternion kamery pocas behu */
  cameraQuaternionLerp: 0.15,


  /**
   * automaticky pohyb
   */
  autoMove: {

    // ci je zapnuty
    isEnabled: false,

    // po kolkych framoch reagujeme pri starte
    startFrames: 3

  },

  /**
   * nastavovanie vetra manualne
   */
  configWind: {
    // ci je zapnute manualne
    isEnabled: false,

    // kolko
    value: 0
  },

  /** Farby lidra */
  colorsLeader: {
    [LeaderColors.green]: new THREE.Color('rgb(25, 167, 51)'),
    [LeaderColors.lightBlue]: new THREE.Color('rgb(87, 255, 247)'),
  },

  /** Farby vlasov */
  hairColors: {
    [HairColorTypes.blonde]: '#fcd681',
    [HairColorTypes.red]: '#ba2c13',
    [HairColorTypes.orange]: '#f56e07',
    [HairColorTypes.brown]: '#6b3e24',
    [HairColorTypes.black]: '#303233',
    [HairColorTypes.purple]: '#7146bd',
    [HairColorTypes.green]: '#61d64d',
    [HairColorTypes.blue]: '#3bdced',
    [HairColorTypes.pink]: '#ff5eb7',
  },

  debugSlipStream: false,
  debugLungeOffset: false,

  /** slovnik pozicii */
  positionDictionary: [
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
  ],

  /** poradie pomocnikov */
  helperManOrder: [0, 1, 2, 1, 0, 2],

  /** ako casto pocitat vzdialenosti medzi hracmi */
  distancesManagementFrequency: 10,

  /** Nastavenie pre sipky */
  arrowDistances: {

    // kolko metrov za hracom zacina zobrazovanie sipok
    limitToShow: 0,

    // kolko metrov za hracom zacina zelena sipka
    limitGreen: 1

  },

  /** debug, skoci hned do tretieho kola */
  debugLastLap: false,

  /** pocet oponentov */
  numberOfOpponents: 5

}
