import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface GamePhaseState {
  showBar: boolean,
  showSmallActionButtons: boolean,
  disabledSmallAction: boolean,
  showUi: boolean,
}

const initialState = () => ({
  showBar: false,
  showSmallActionButtons: true,
  disabledSmallAction: false,
  showUi: true,
})

const gamePhaseState: Module<GamePhaseState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getGamePhaseState: (moduleState: GamePhaseState) => moduleState,
    getSmallActionButtons: (moduleState: GamePhaseState) => moduleState.showSmallActionButtons,
    getDisabledSmallAction: (moduleState: GamePhaseState) => moduleState.disabledSmallAction,
  },

  mutations: {
    RESET: (moduleState: GamePhaseState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: GamePhaseState, newState: GamePhaseState) => {

      if (moduleState.showBar !== undefined) moduleState.showBar = newState.showBar

    },
    SET_SHOW_UI: (moduleState: GamePhaseState, showUi: boolean) => {

      moduleState.showUi = showUi

    },
    SET_SMALL_ACTION: (moduleState: GamePhaseState, showButtons: boolean) => {

      moduleState.showSmallActionButtons = showButtons

    },
    SET_SHOW: (moduleState: GamePhaseState, isShown: boolean) => {

      moduleState.showBar = isShown

    },
    SET_DISABLED_SMALL_BUTTONS: (moduleState: GamePhaseState, disabled: boolean) => {

      moduleState.disabledSmallAction = disabled

    },

  }
}

export default gamePhaseState
