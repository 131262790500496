import {
  gsap,
  CameraStates,
  corePhasesManager,
  modes,
  cameraManager,
  audioManager
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  PlayerStates,
  AudioNames
} from '../../types'
import store from '@/store'
import { player } from '../../entities/athlete/player'
import { gameConfig } from '../../config'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { trainingTasks } from '@/app/modes/training'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci sa deje nieco skipnutelne v druhej faze */
  private skippableSecond = true

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci faza skoncila */
  public ended = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween?: gsap.core.Tween

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** Premenna pre kameru */
  private cameraInPostIntroState = false

  /** zobrazenie bielej ziary */
  private showShine = false

  /** Ci sa uz odstartovalo alebo nie */
  private started = false

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    startPhaseStateManager.disableInputs()
    startPhaseStateManager.enableStartInputs()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    trainingTasks.setMaxSpeedReq()
    if (this.ended) {

      this.reset()
      if (modes.isTutorial()) {

        this.afterCameraDisciplineIntro()
        return

      }

      this.secondCameraDisciplineIntro()
      this.removeBlackOverlay()
      return

    }

    console.warn('starting start phase')
    // Zobrazit meno hraca v UI
    this.showName = !modes.isTutorial()
    this.setCameraForDisciplineIntro()

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    // musime doplnit vsade hracovu poziciu pre kamerove obejkty a potom nastavit tweeny
    player.updateCameraConfigOnStart(CameraStates.disciplineIntro)

    if (gameConfig.cameraConfig.enabled) {

      player.setGameCameraSettings()

    }

    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntro()
      tutorialFlow.init()
      tutorialUIChange.init()
      return

    }

    audioManager.play(AudioNames.audienceIntroduction)
    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.secondCameraDisciplineIntro)

  }

  /** pomocna metoda pre animacie */
  public getCameraInPostIntroState(): boolean {

    return this.cameraInPostIntroState

  }

  /**
   * Druhe disciplinove kamerove intro
   */
  private secondCameraDisciplineIntro = (): void => {

    this.skippable = false
    // Zrusit zobrazenie mena hraca v UI
    this.showName = false

    if (modes.isTrainingMode()) {

      this.afterCameraDisciplineIntro()
      return

    }

    // musime dat chvilku pauzu, aby sa dalo skipovat 2x po sebe
    gsap.to({}, {
      duration: 0.1,
      onComplete: () => {

        // musime zmenit hracovu animaciu naspat
        player.setState(PlayerStates.prepare2)

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cameraManager.setState(CameraStates.disciplineIntroSecond)
        cameraManager.playTween(false, this.afterCameraDisciplineIntro)

        this.skippableSecond = true

      }
    })

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  private afterCameraDisciplineIntro = (): void => {

    this.skippable = false
    this.skippableSecond = false

    // nastavime spravnu poziciu
    player.worldEnvLinesManager.setActualPercentOnStart()
    opponentsManager.setActualPercentOnStart()

    this.cameraInPostIntroState = true

    cameraManager.setState(CameraStates.discipline)
    cameraManager.changeBaseRenderSettings(undefined, undefined, gameConfig.cameraConfig.fov)

    startPhaseStateManager.postIntroUiState()

    const showFirstLine = true
    const showSecondLine = false

    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)
    this.storeState()
    this.startMovingPlayer()

  }

  /**
   * Zacatie hybania sa hraca
   */
  private startMovingPlayer(): void {

    store.commit('GamePhaseState/SET_SHOW', false)
    store.commit('ActionButtonState/SET_START_BUTTON', false)

    this.finishPhase()
    // this.ended = true

  }

  /**
   * Inputy pri starte
   */
  public handleInputs(): void {

    if (this.ended) return
    // odstartovanie
    // if (this.startable) {

    //   this.startMovingPlayer()

    // }

    // skip intra
    if (this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

    // skip intra druheho
    if (this.skippableSecond && this.framesInPhase > 10) {

      this.skippableSecond = false
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    if (!this.ended) this.framesInPhase++

    const { isEnabled, startFrames } = gameConfig.autoMove
    if (this.started && isEnabled && this.framesInPhase >= startFrames) {

      // auto odstartovanie
      console.log(`auto start po ${this.framesInPhase} frameoch`)
      this.startMovingPlayer()

    }

    this.storeState()

  }

  /**
   * Create black overlay
   */
  public createBlackOverlay(): void {

    store.commit('BlackOverlayState/SET_BLACK_SCREEN', true)

  }

  /**
   * Remove black overlay
   */
  public removeBlackOverlay(): void {

    store.commit('BlackOverlayState/SET_BLACK_SCREEN', false)

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    if (!this.ended) {

      console.warn('start phase ended')

      this.ended = true
      this.callbackEnd()
      player.start()
      opponentsManager.start()

      this.storeState()

      startPhaseStateManager.finishPhaseUiState()
      // startPhaseStateManager.disableInputs()

    }

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    store.commit('StartPhaseState/SET_STATE', {
      showName: this.showName,
      showPhase: !this.ended,
      attempt: corePhasesManager.disciplineActualAttempt,
      showShine: this.showShine
    })

  }

  /**
   * reset fazy
   */
  private reset(): void {

    store.commit('InputsState/SET_DISABLED', true)
    this.skippable = true
    this.skippableSecond = false
    this.showName = false
    this.ended = false
    this.clickedPower = 0
    this.started = false
    this.framesInPhase = 0
    this.showShine = false
    this.startingMessageTween = undefined
    this.cameraInPostIntroState = false
    this.storeState()

  }

}
