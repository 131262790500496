<template>
  <game-keirin-component />
</template>

<script lang="ts">
import GameKeirinComponent from '@/components/GameKeirinComponent.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    GameKeirinComponent
  },
})

</script>
