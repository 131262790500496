import { cameraConfig } from '@/app/config'
import { Sides } from '@/app/types'
import {
  cameraManager,
  vibrations
} from '@powerplay/core-minigames'
/**
 * Trieda pre camera shake efekt
 */
export class CameraShake {

  /** ci je efekt aktivny akurat */
  public enabled = false

  /** pocitadlo freeznutia */
  private freezeFrameCounter = 0

  /** Aktualny smer, kam ide efekt */
  private dir = 0

  /** aktualna cast efektu (mame parts v konfigu) */
  private actualPart = 0

  /** aktualny limit do jednej alebo druhej strany, dynamicky sa zmensuje kazdou castou */
  private offsetLimit = 0

  /** aktualny step v danom smere (dir) */
  private step = 0

  /** aktualny typ (ci hore alebo do jednej zo stran) */
  private type: Sides | undefined

  /** Aktualny offset, ktory sa meni cez step */
  private actualOffset = 0

  /** Freeznutie typu, aby to nerobilo stale a nebolo otravne */
  private freezedType: Sides | undefined | null = null

  /**
   * zmena strany efektu
   */
  private changeSide(): void {

    this.actualPart += 1
    this.dir *= -1
    this.offsetLimit *= -cameraConfig.shake.coefSlowDown
    this.step /= cameraConfig.shake.coefSlowDown

    if (this.actualPart === cameraConfig.shake.parts) {

      this.enabled = false
      this.freezeFrameCounter = cameraConfig.shake.freezeFrames

    }

  }

  /**
   * Pohyb kamery
   */
  private translateCamera(): void {

    if (this.type === Sides.LEFT) {

      cameraManager.getMainCamera().translateX(this.actualOffset)

    }

    if (this.type === Sides.RIGHT) {

      cameraManager.getMainCamera().translateX(-this.actualOffset)

    }

    if (this.type === undefined) {

      cameraManager.getMainCamera().translateY(-this.actualOffset)

    }

  }

  /**
   * Aktualizacia efektu v case
   */
  public update(): void {

    if (this.freezeFrameCounter > 0) {

      this.freezeFrameCounter -= 1
      return

    }

    if (!this.enabled) return

    let changeSide = false

    this.actualOffset += (this.dir * this.step)
    if (this.dir === -1 && this.actualOffset < this.offsetLimit) {

      this.actualOffset = this.offsetLimit
      changeSide = true

    }
    if (this.dir === 1 && this.actualOffset > this.offsetLimit) {

      this.actualOffset = this.offsetLimit
      changeSide = true

    }

    this.translateCamera()

    if (changeSide) this.changeSide()

  }

  /**
   * Zatrasenie kamerou
   * @param type - Typ
   */
  public shake(type: Sides | undefined): void {

    // nerobime shake, ak sa uz prehrava alebo ked nie je dostupny alebo freeznuty
    if (this.enabled || !cameraConfig.shake.enabled || this.freezeFrameCounter > 0 || this.freezedType === type) return

    this.type = type
    this.enabled = true
    this.dir = -1
    this.step = cameraConfig.shake.step
    this.offsetLimit = cameraConfig.shake.firstOffsetLimit * this.dir
    this.actualOffset = 0
    this.actualPart = 0
    this.freezedType = type
    vibrations.vibrate()

  }

  /**
   * Resetovanie freezedType
   */
  public resetFreezedType(): void {

    this.freezedType = null

  }

}

export const cameraShake = new CameraShake()
